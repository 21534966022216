<template>
    <div class="banner">

    </div>
</template>

<script>
    export default {
        name: "RightBarBannerComp"
    }
</script>

<style scoped>

    .banner{
        width: 100%;
        padding: 5px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
</style>