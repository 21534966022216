<template>
    <!--메인메뉴 패널-->
    <div>
        <div class="mobile-menu-wapper" :class="{'activeMobileMenu':isShowTopMenu}">
            <div class="close" @click="closeMobileMenu()">
                <i class="fa fa-close" aria-hidden="true"></i>
            </div>
            <div class="content" style="">
                <div style="width: 100%;text-align: center;line-height: 32px;display: flex;flex-wrap: nowrap;align-items: center;justify-content: flex-start">

                    <div class="l" style="">
                        <img  src="../../assets/images/logo/betstoa/logo.png">
                    </div>
                    <select @change="changeCountry($event)"  v-model="$store.state.country" style="width: 100px">
                        <option :value="i18nConst.EN" >English</option>
                        <option :value="i18nConst.KR">한국</option>
                        <option :value="i18nConst.JP">日本語</option>
                        <option :value="i18nConst.FRA" >Français</option>
                        <option :value="i18nConst.SPA" >Español</option>
                    </select>
                </div>

                <mobile-menu-items-comp></mobile-menu-items-comp>
            </div>
        </div>

    </div>

</template>

<script>
    import sportsConst from "../../common/sportsConst";
    import leisureConst from "../../common/leisureConst";
    import MobileMenuItemsComp from "../MobileMenuItemsComp";
    import {setCountry} from "../../network/userRequest";
    import i18nConst from "../../common/i18nConst";

    export default {
        name: "MobileTopMenu",
        components: {MobileMenuItemsComp},
        data() {
            return {
                sportsConst,
                leisureConst,
                isShowTopMenu: false,
                cross: sportsConst.GAME_DISPLAYPOSITION_1x2,
                handicap: sportsConst.GAME_DISPLAYPOSITION_HANDICAPOVERUNDER,
                special: sportsConst.GAME_DISPLAYPOSITION_SPECIAL,
                live: sportsConst.GAME_DISPLAYPOSITION_LIVE,
                i18nConst:i18nConst
            }
        },
        methods: {
            changeCountry(c) {
                if(c.target.value == 'kr'){
                    this.$store.state.country = i18nConst.KR
                    setCountry(i18nConst.KR)
                }
                else if(c.target.value == 'en'){
                    this.$store.state.country = i18nConst.EN
                    setCountry(i18nConst.EN)
                }
                else if(c.target.value == 'jp'){
                    this.$store.state.country = i18nConst.JP
                    setCountry(i18nConst.JP)
                }
                else if(c.target.value == 'fra'){
                    this.$store.state.country = i18nConst.FRA
                    setCountry(i18nConst.FRA)
                }
                else if(c.target.value == 'spa'){
                    this.$store.state.country = i18nConst.SPA
                    setCountry(i18nConst.SPA)
                }
            },
            setCountry(c) {
                this.$store.state.country = c
                setCountry(c)
            },
            closeMobileMenu(disp) {
                this.isShowTopMenu = false
                if (disp) {
                    this.$bus.$emit('sportsDisplayChanged', disp);
                }
            }
        },
        created() {
            this.$bus.$on('mobileTopMenuActive', () => {
                this.isShowTopMenu = true;
            })
        }
    }
</script>

<style scoped>

    /*모바일 메뉴*/
    .mobile-menu-wapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: #fff9ff;
        z-index: 99999;
        overflow-y: scroll;
        transform: translateX(-100%);
        transition: 0.3s transform;
        box-sizing: border-box;
        padding: 10px 3px;
    }

    .mobile-menu-wapper .content {
        width: 100%;
        height: 100vh;
    }
    .mobile-menu-wapper .content .l{
        width: 60%;
        text-align: left;

    }
    .mobile-menu-wapper .content .l img{
        height: 50px;
    }

    .mobile-menu-wapper .close {
        color: #c51a1b;
        font-size: 28px;
        font-weight: 600;
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
    }
    .activeMobileMenu {
        transform: translateX(0%) !important;
    }
</style>