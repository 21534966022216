<template>
    <div class="reg_content">
<!--        <div class="reg_panel">-->
<!--            <div class="form mt">-->
<!--                <div class="fitem">-->
<!--                    <label>아이디</label>-->
<!--                    <input v-model="user.username" type="text" placeholder="4-15자리 영문,숫자 조합">-->
<!--                </div>-->
<!--                <div class="fitem">-->
<!--                    <label>닉네임</label>-->
<!--                    <input v-model="user.nickname" type="text" placeholder="2-8자리 한글,영문,숫자">-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="form mt">-->
<!--                <div class="fitem">-->
<!--                    <label>비밀번호</label>-->
<!--                    <input v-model="user.passwd" type="password" placeholder="4-20자리 영문,숫자">-->
<!--                </div>-->
<!--                <div class="fitem">-->
<!--                    <label>비밀번호 확인</label>-->
<!--                    <input v-model="user.confirm_password" type="password" placeholder="비밀번호 확인">-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="form mt">-->
<!--                <div class="fitem">-->
<!--                    <label>휴대폰</label>-->
<!--                    <input v-model="user.phone" type="text" placeholder="`-` 없이 숫자만 입력">-->
<!--                </div>-->
<!--                <div class="fitem">-->
<!--                    <label>예금주</label>-->
<!--                    <input v-model="user.beneficiary" type="text" placeholder="환전 예금주명">-->
<!--                </div>-->

<!--            </div>-->
<!--            <div class="form mt">-->
<!--                <div class="fitem">-->
<!--                    <label>은행선택</label>-->
<!--                    <select style="font-size: 13px;padding: 3px 0;width: 95%" v-model="user.bank">-->
<!--                        <option value="">- - - 은행선택 - - -</option>-->
<!--                        <option value="카카오뱅크">카카오뱅크</option>-->
<!--                        <option value="신한">신한은행</option>-->
<!--                        <option value="국민">국민은행</option>-->
<!--                        <option value="농협">농협</option>-->
<!--                        <option value="우리">우리은행</option>-->
<!--                        <option value="하나">하나은행</option>-->
<!--                        <option value="기업">기업은행</option>-->
<!--                        <option value="우체국">우체국</option>-->
<!--                        <option value="대구">대구은행</option>-->
<!--                        <option value="경남">경남은행</option>-->
<!--                        <option value="광주">광주은행</option>-->
<!--                        <option value="부산">부산은행</option>-->
<!--                        <option value="산림조합">산림조합</option>-->
<!--                        <option value="산업">산업은행</option>-->
<!--                        <option value="수협">수협은행</option>-->
<!--                        <option value="신협">신협은행</option>-->
<!--                        <option value="전북">전북은행</option>-->
<!--                        <option value="제주">제주은행</option>-->
<!--                        <option value="씨티">씨티은행</option>-->
<!--                        <option value="새마을">새마을금고</option>-->
<!--                    </select>-->
<!--                </div>-->
<!--                <div class="fitem">-->
<!--                    <label>계좌번호</label>-->
<!--                    <input v-model="user.acNo" type="text" placeholder="`-` 없이 숫자만 입력">-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="form mt">-->
<!--                <div class="fitem">-->
<!--                    <label>가입코드</label>-->
<!--                    <input type="text" v-model="user.code" placeholder="총판,회원 추천코드">-->
<!--                </div>-->
<!--                <div class="fitem">-->
<!--                    <label style="color: #182166">.</label>-->
<!--                    <button @click="doRegister" class="btn-register">가입하기</button>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="err" v-show="erm != null">-->
<!--                <i class="fa fa-warning"></i> {{erm}}-->
<!--            </div>-->


<!--        </div>-->

    </div>


</template>

<script>
    import {
        checkUserName,
        checkNickName,
        getVerificationCode,
        register,
        getSiteStatus, logout
    } from "../../network/userRequest";
    import {loginRegisterMixin} from "../../common/mixin";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import TopbarComp from "../../components/TopbarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import RightBarComp from "../../components/RightBarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import FootComp from "../../components/FootComp";

    export default {
        name: "register",
        components: {FootComp, RightBarBannerComp, RightBarComp, SportsLeftBarComp, LeftBarComp, TopbarComp},
        mixins: [loginRegisterMixin],
        data() {
            return {
                user: {
                    username: '',
                    passwd: '',
                    confirm_password: '',
                    nickname: '',
                    phone: '',
                    verificationCode: '',
                    bank: '',
                    beneficiary: '',
                    acNo: '',
                    extractPasswd: '',
                    code: '',
                    telcomp: 'SKT',
                    byear: '2000',
                    bmonth: '1',
                    bday: '1',
                },
                error: {
                    username: '',
                    passwd: '',
                    confirm_password: '',
                    nickname: '',
                    phone: '',
                    verificationCode: '',
                    bank: '',
                    beneficiary: '',
                    acNo: '',
                    extractPasswd: '',
                    code: ''
                },
                status: {
                    username: false,
                    passwd: false,
                    confirm_password: false,
                    nickname: false,
                    phone: false,
                    verificationCode: false,
                    bank: false,
                    beneficiary: false,
                    acNo: false,
                    extractPasswd: false,
                    code: false
                },
                isUsePhoneCode: false,
                erm: null,
                syear: [],
                smonth: [],
                sday: [],
            }
        },
        methods: {
            getVerifyPhoneCode() {
                if (this.checkPhone()) {
                    this.$store.commit(RECEIVE_SHOW_LOADING)
                    getVerificationCode(this.user.phone).then(res => {
                        this.$store.commit(RECEIVE_HIDE_LOADING)
                        if (res.data.success) {
                            this.$swal({
                                title: '휴대폰 인증코드가 전송되였습니다. 휴대폰 문자를 확인해주세요',
                                type: 'success',
                                background: '#ffffff',
                                showCancelButton: false,
                                showConfirmButton: true
                            })
                        } else {
                            this.erm = res.data.msg
                        }

                    })
                }
            },
            checkUserName() { //회원가입시 아이디 체크
                if (!this.testRegxUserName) {
                    this.error.username = '아이디는 4-15자리 영문,숫자조합입니다'
                    this.status.username = false;
                    return false;
                }
                this.error.username = '';
                return true;
                /*//아이디 사용가능 여부 체크
                this.$store.commit(RECEIVE_SHOW_LOADING)
                checkUserName(this.user.username).then(res => {
                    if (res.data.success) {
                        this.error.username = ''
                        this.status.username = true;
                    } else {
                        this.error.username = res.data.msg
                        this.status.username = false;
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                })*/
            },
            checkNickName() { //회원가입시 닉네임 체크
                if (!this.testRegxNickname) {
                    this.error.nickname = '닉네임은 2-6자리 한글만 가능합니다'
                    this.status.nickname = false;
                    return false;
                }
                this.error.nickname = '';
                return true;
                //아이디 사용가능 여부 체크
                /*this.$store.commit(RECEIVE_SHOW_LOADING)
                checkNickName(this.user.nickname).then(res => {
                    if (res.data.success) {
                        this.error.nickname = ''
                        this.status.nickname = true;
                    } else {
                        this.error.nickname = res.data.msg
                        this.status.nickname = false;
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                })*/
            },
            checkPasswd() {
                if (!this.testRegxPasswd) {
                    this.error.passwd = '비밀번호는 4-20자리 입니다';
                    this.status.passwd = false;
                    return false;
                }
                this.error.passwd = '';
                this.status.passwd = true;
                return true;
            },
            checkConfirmPassword() {
                if (this.user.confirm_password.trim() === '' || this.user.passwd.trim() !== this.user.confirm_password.trim()) {
                    this.error.confirm_password = '입력하신 비밀번호가 동일하지 않습니다'
                    this.status.confirm_password = false;
                    return false;
                }
                this.error.confirm_password = '';
                this.status.confirm_password = true;
                return true;

            },
            checkPhone() {
                if (!/^[0-9]{11,12}$/.test(this.user.phone)) {
                    this.error.phone = '폰번호 오류입니다'
                    this.status.phone = false;
                    return false;
                }
                this.error.phone = '';
                this.status.phone = true;
                return true;

            },
            checkBank() {
                if (this.user.bank === '') {
                    this.error.bank = '은행명을 선택하세요'
                    this.status.bank = false;
                    return false;
                }
                this.error.bank = ''
                this.status.bank = true;
                return true;
            },
            checkBeneficiary() {
                if (!/^.{1,10}$/.test(this.user.beneficiary)) {
                    this.error.beneficiary = '예금주를 입력하세요'
                    this.status.beneficiary = false;
                    return false;
                }
                this.error.beneficiary = ''
                this.status.beneficiary = true;
                return true;

            },
            checkAcNo() {
                if (!/^[0-9]{5,20}$/.test(this.user.acNo)) {
                    this.error.acNo = '계좌번호 오류입니다.\'-\'없이 숫자로만 입력하세요'
                    this.status.acNo = false;
                    return false;
                }
                this.error.acNo = '';
                this.status.acNo = true;
                return true;

            },
            checkCode() {
                if (!/^[0-9a-zA-Z]{2,50}$/.test(this.user.code)) {
                    this.error.code = '추천인 코드를 정확하게 입력하세요';
                    this.status.code = false;
                    return false;
                }
                this.error.code = '';
                this.status.code = true;
                return true;
            },
            doRegister() {
                if (!this.checkConfirmPassword()) {
                    // this.$swal({
                    //     title: '입력하신 비밀번호가 동일하지 않습니다',
                    //     type: 'error',
                    //     showCancelButton: false,
                    //     showConfirmButton: true
                    // })
                    this.erm = '입력하신 비밀번호가 동일하지 않습니다'
                    return false;
                }
                this.$store.commit(RECEIVE_SHOW_LOADING)
                register(this.user).then(res => {
                    if (res.data.success) {
                        this.$swal({
                            title: '회원가입을 축하드립니다.관리자 확인후 이용가능합니다',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        this.erm = null
                        this.$router.replace('/login')
                    } else {
                        // this.$swal({
                        //     title: res.data.msg,
                        //     type: 'error',
                        //     showCancelButton: false,
                        //     showConfirmButton: true
                        // })
                        this.erm = res.data.msg
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                })
            },
        },
        created() {
            /*getSiteStatus().then(res=>{
                if(!res.data.success){
                    this.$router.push("/login")
                }
            })*/
            let b = 1949
            for (let i = 1; i <= 71; i++) {
                let bb = b + i;
                this.syear.push(bb)
            }
            for (let i = 1; i <= 12; i++) {
                this.smonth.push(i)
            }
            for (let i = 1; i <= 31; i++) {
                this.sday.push(i)
            }

        }
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");

    .reg_content {
        width: 100%;
        height: 100vh;
        position: fixed;
    }

    .reg_panel {
        width: 522px;
        height: 500px;
        position: absolute;
        left: 50%;
        top: 50%;
        background-size: cover;
        -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
        transform: translateX(-50%) translateY(-50%) scale(1);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border-radius: 10px;
        background: rgba(34,34,34,.9);
        border: 0px solid #b29d82;
        border-image: linear-gradient(to right, #b29d82, #b29d82) 1;
        box-shadow: 0 0 20px #b29d82;
    }

    .reg_panel input {
        border: 1px solid #9a9a9a;
        border-radius: 1px;
    }

    .logo {
        margin-top: 00px;
        position: absolute;
        left: 50%;
        top: -200px;
        background-size: cover;
        -webkit-transform: translateX(-50%) scale(1);
        transform: translateX(-50%) scale(1);

    }

    .form {
        width: 100%;
        height: 40px;
        padding: 0 5px 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
    }

    .form .fitem {
        width: 49.5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-top: 15px;
    }

    .fitem label {
        padding: 5px 0;
        font-size: 14px;
        color: #c6c6c6;
    }

    .fitem input {
        width: 95%;
        text-align: center;
        height: 32px;
        line-height: 32px;
    }

    .mt {
        margin-top: 25px;
    }

    .err {
        color: #ff4d4d;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        margin-top: 40px;
        animation: sansan 700ms infinite;
        -webkit-animation: sansan 700ms infinite;
    }

    .reg_tel {
        position: absolute;
        bottom: 20px;
        width: 70%;
        height: 100px
    }

    .btn-register {
        height: 32px;
        background-color: #fa9841;
        width: 95%;
        color: #151712;
        font-size: 16px;
        font-weight: bold;
        border-radius: 5px;
    }

    @media screen and (max-width: 1024px) {
        .reg_panel {
            width: 375px !important;
            height: 500px !important;
            background-size: 100% !important;
            transform: translateX(-50%) translateY(-35%) scale(1) !important;
        }

        .reg_tel {
            bottom: -50px !important;
        }
    }

    @keyframes sansan {
        from {
            opacity: 1.0;
        }
        70% {
            opacity: 0.8;
        }
        50% {
            opacity: 0.6;
        }
        to {
            opacity: 1.0;
        }
    }

    @-webkit-keyframes sansan {
        from {
            opacity: 1.0;
        }
        50% {
            opacity: 0.4;
        }
        to {
            opacity: 1.0;
        }
    }
</style>