<template>
    <div class="overlay" :class="{'showLoginPannel':$store.state.isShowLoginPanel && !$store.state.isLogin}">
        <div class="overlay-warp">
            <div class="overlay-open">

                <div class="login-warp">
                    <div class="login-head">
                        <h4>LOG IN TO BETSTOA</h4>
                        <div class="login-panel-close" @click="closePanel">
                            <img src="../assets/images/icon/common/close1.svg"/>
                        </div>
                    </div>
                    <div class="login-cont">
                        <div class="login-d">
                            <div class="login-left">

                                <div class="login-form">
                                    <div class="form-field">
                                        <span>{{i18nConst.USER['USER_NAME'][$store.state.country]}}</span>
                                        <input type="text" :placeholder="i18nConst.USER['USER_NAME'][$store.state.country]"  v-model="user.username"/>
                                    </div>
                                    <div class="form-field">
                                        <span>{{i18nConst.USER['PASSWORD'][$store.state.country]}}</span>
                                        <input type="password" :placeholder="i18nConst.USER['PASSWORD'][$store.state.country]" v-model="user.passwd"/>
                                    </div>
                                </div>
                                <div class="error-message">
                                    {{erm}}
                                </div>
                                <div class="login-form-button">
                                    <loading-login-comp v-if="clickedLogin"></loading-login-comp>
                                    <button class="mb-button--primary mb-button--wider login" @click="doLogin"  v-if="!clickedLogin">{{i18nConst.COMMON['LOGIN'][$store.state.country]}}</button>

                                </div>
                                <div class="login-bottom">
                                    <button class="mb-button--empty mb-button--wider register" @click="showRegisterPanel">{{i18nConst.COMMON['REGISTE'][$store.state.country]}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING, RECEIVE_USER_INFO} from "../store/mutation-types";
    import {loginRegisterMixin} from "../common/mixin";
    import {getUserInfo, login} from "../network/userRequest";
    import LoadingLoginComp from "./LoadingLoginComp";
    import i18nConst from "../common/i18nConst";

    export default {
        name: "LoginComp",
        components: {LoadingLoginComp},
        mixins: [loginRegisterMixin],
        data() {
            return {
                i18nConst:i18nConst,
                user: {
                    username: '',
                    passwd: '',
                },
                isShowMessage: false,
                content: '',
                erm:'',
                telMessage:null,
                clickedLogin: false
            }
        },
        methods: {
            showRegisterPanel() {
                if (!this.$store.state.isLogin) {
                    this.$store.state.isShowRegisterPanel = true
                    this.$store.state.isShowLoginPanel = false
                }
            },
            closePanel() {
                this.$store.state.isShowLoginPanel = false;
                this.$store.state.isShowRegisterPanel = false
            },
            doLogin() {
                this.clickedLogin = true
                // if (!this.testRegxUserName) {
                //     this.$swal({
                //         title: '아이디는 4-15자리 영문,숫자조합입니다',
                //         type: 'error',
                //         showCancelButton: false,
                //         showConfirmButton: true
                //     })
                //     return false;
                // }
                // if (!this.testRegxPasswd) {
                //     this.$swal({
                //         title: '비밀번호는 4-20자리 입니다',
                //         type: 'error',
                //         showCancelButton: false,
                //         showConfirmButton: true
                //     })
                //     return false;
                // }
                login(this.user).then(res => {
                    if (res.data.success) {
                        getUserInfo().then(res => {
                            this.$store.state.isLogin = true;
                            this.$store.state.isShowLoginPanel = false
                            this.$store.state.isShowRegisterPanel = false
                            const userInfo = res.data.data
                            this.$store.commit(RECEIVE_USER_INFO, {userInfo});
                            window.sessionStorage.setItem("isLogin", 'true');
                        }, err => {
                            console.log(err)
                        })
                    } else {
                        this.erm=res.data.msg
                    }
                    this.clickedLogin = false;
                })
            },
            donotlogin() {
                this.telMessage =  'Telegram: @ 로 연락주세요'
            },

        },
        created() {
        },
        computed: {
            allowUsername() {
                return /^[a-z][0-9a-z]{3,20}$/.test(this.username)
            },
            allowPasswd() {
                return /^.{3,30}$/.test(this.passwd)
            }
        }
    }
</script>

<style scoped>

    @media screen and (max-width: 1024px) {
        .login-warp {
            width: 370px !important;
            height: 600px;
            background-color: #fff9ff;
        }
        .login-warp .login-cont .login-d .login-left{
            width: 100% !important;
        }
        .login-warp .login-cont .login-d .login-right{
            display: none!important;
        }
    }
    .showLoginPannel{
        transform: translateY(0%);
    }

    .login-warp {
        width: 400px;
        height: 430px;
        background-color: #fff9ff;
        border-radius: 5px;
    }

    .login-warp .login-head {
        background-color: #0b3987;
        color: #fff;
        height: 40px;
        line-height: 40px;
        padding: 0 16px;
        position: relative;
        text-transform: uppercase;
        width: 100%;
        z-index: 5002;
    }

    .login-warp .login-head h4 {
        -webkit-align-items: center;
        align-items: center;
        display: -webkit-inline-flex;
        display: inline-flex;
        font-size: 14px;
        font-weight: 700;
        -webkit-justify-content: center;
        justify-content: center;
        margin: 0;
        vertical-align: middle;
    }

    .login-panel-close {
        position: absolute;
        top: 0;
        right: 0;
        width: auto;
        height: 40px;
        padding: 2px;
        cursor: pointer;
    }

    .login-panel-close img {
        height: 30px;
    }

    .login-warp .login-cont {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        overflow: hidden;
        position: relative;
        z-index: 5001;
        margin-top: -40px;
    }

    .login-warp .login-cont .login-d {
        display: flex;
        width: 100%;
        height: 100%;
        padding-top: 40px;
    }

    .login-warp .login-cont .login-d .login-left {
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 5002;
        position: relative;
    }
    .error-message{
        text-align: center;
        font-size: 14px;
        color: #ff0000;
    }

    .login-warp .login-cont .login-d .login-left  .login-logo {
        text-align: center;
        margin-top: 24px;
    }

    .login-warp .login-cont .login-d .login-left  .login-form{
        width: 100%;
        padding: 0 16px 16px;
    }

    .login-warp .login-cont .login-d .login-left  .login-form .form-field {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 64px;
        margin-top: 20px;
    }
    .login-warp .login-cont .login-d .login-left  .login-form .form-field span{
        height: 28px;
        line-height: 28px;
        font-weight: bold;
        font-size: 13px;
    }
    .login-warp .login-cont .login-d .login-left  .login-form .form-field input{
        background: 0 0;
        border: 0;
        border-bottom: 1px solid #a3a1a7;
        border-radius: 0;
        color: #43444a;
        font-size: 15px;
        height: 25px;
        line-height: 25px;
        outline: 0;
        padding: 0;
        width: 100%;
        text-align: left;
    }
    .login-warp .login-cont .login-d .login-left  .login-form-button{
        width: 100%;
        padding: 0 16px 16px;
        margin-top: 28px;
    }
    .login-warp .login-cont .login-d .login-left  .login{
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        font-weight: bold;
    }
    .login-warp .login-cont .login-d .login-left  .register{
        height: 36px;
        line-height: 36px;
        margin-top: 28px;
        font-weight: bold;
        font-size: 14px;
    }

    .login-warp .login-cont .login-d .login-left .tel-message{
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: #0b1423;
        line-height: 28px;
    }

    .login-warp .login-cont .login-d .login-left .login-bottom{
        width: 100%;
        background-color: #0b3987;
        position: absolute;
        bottom: 0;
        padding: 16px;
    }
    .login-warp .login-cont .login-d .login-left .login-bottom .join-text{
        color: #fff9ff;
        font-size: 12px;
    }

        /*login panel right*/
    .login-warp .login-cont .login-d .login-right {
        width: 50%;
        height: 100%;
        overflow: hidden;
        z-index: 5002;
    }

</style>