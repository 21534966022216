import {getUnCheckedMessageCount, getUserInfo} from '../network/userRequest'
import {getCommonConfig} from "../network/configRequest";

import {
  RECEIVE_USER_INFO,//회원정보
  RECEIVE_COMMON_CONFIG,//설정
  RECEIVE_UNCHEKCED_MESSAGE_COUNT,//스포츠경기
} from './mutation-types'
import {coinBalance, coinPrice} from "../network/coinRequest";

export default {

  actionUserInfo({commit, state}) {
    getUserInfo().then(res => {
      const userInfo = res.data.data
      commit(RECEIVE_USER_INFO, {userInfo});
    }, err => {
      console.log(err)
    })
  },
  actionUnCheckedMessageCount({commit,state}){
    getUnCheckedMessageCount().then(res=>{
      const count = res.data.data
      commit(RECEIVE_UNCHEKCED_MESSAGE_COUNT, {count});
    }, err => {
      console.log(err)
    })
  },
  actionCommonConfig({commit,state}){
    getCommonConfig().then(res=>{
      const commonConfig = res.data.data
      commit(RECEIVE_COMMON_CONFIG, {commonConfig});
    }, err => {
      console.log(err)
    })
  },
  actionCoinPriceAndBalance({commit,state}){
    coinPrice().then(res=>{
      if(res.data.success){
        let d = res.data.data;
        if(d.sta){
          state.staPrice =  d.sta
        }
        if(d.eth){
          state.ethPrice = d.eth
        }
      }
    })
    coinBalance().then(res=>{
      if(res.data.success){
        let d = res.data.data;
        if(d.sta){
          state.staBalance =  d.sta
          state.staWallet =  d.staWallet
        }
        if(d.eth){
          state.ethBalance = d.eth
          state.ethWallet = d.ethWallet
        }
      }
    })
  },
}