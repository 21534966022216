<template>
    <div class="menu-items">
        <router-link to="/sports" tag="div" class="mitem">
            <img src="../assets/images/icon/common/mobile_sports1.svg" alt="">
            <div class="itext">
               {{i18nConst.MENU['SPORTS'][$store.state.country]}}
            </div>
        </router-link>
        <router-link to="/sports_inplay" tag="div" class="mitem">
            <img src="../assets/images/icon/common/mobile_inplay.svg" alt="">
            <div class="itext">
               {{i18nConst.MENU['INPLAY'][$store.state.country]}}
            </div>
        </router-link>
        <router-link :to="{path:'/leisure/bet365_premiership'}" tag="div" class="mitem">
            <img src="../assets/images/icon/common/mobile_vsports.svg" alt="">
            <div class="itext">
                {{i18nConst.MENU['VSPORTS'][$store.state.country]}}
            </div>
        </router-link>
        <router-link to="/casino" tag="div" class="mitem">
            <img src="../assets/images/icon/common/mobile_casino.svg" alt="">
            <div class="itext">
                {{i18nConst.MENU['CASINO'][$store.state.country]}}
            </div>
        </router-link>
        <router-link to="/recharge" tag="div" class="mitem">
            <img src="../assets/images/icon/common/mobile_recharge.svg" alt="">
            <div class="itext">
                {{i18nConst.MENU['COININ'][$store.state.country]}}
            </div>
        </router-link>
        <router-link to="/exchange" tag="div" class="mitem">
            <img src="../assets/images/icon/common/mobile_exchange.svg" alt="">
            <div class="itext">
                {{i18nConst.MENU['COINOUT'][$store.state.country]}}
            </div>
        </router-link>

        <router-link to="/customer_center" tag="div" class="mitem">
            <img src="../assets/images/icon/common/mobile_cust.svg" alt="">
            <div class="itext">
                {{i18nConst.MENU['QUESTION'][$store.state.country]}}
            </div>
        </router-link>
        <router-link to="/sports_betinfo" tag="div" class="mitem">
            <img src="../assets/images/icon/common/mobile_bet_list.svg" alt="">
            <div class="itext">
                {{i18nConst.MENU['BETLIST'][$store.state.country]}}
            </div>
        </router-link>
        <router-link to="/notice" tag="div" class="mitem">
            <img src="../assets/images/icon/common/mobile_notice.svg" alt="">
            <div class="itext">
                {{i18nConst.MENU['NOTICE'][$store.state.country]}}
            </div>
        </router-link>
        <router-link to="/event" tag="div" class="mitem">
            <img src="../assets/images/icon/common/mobile_event.svg" alt="">
            <div class="itext">
                {{i18nConst.MENU['EVENT'][$store.state.country]}}
            </div>
        </router-link>
        <router-link to="/message" tag="div" class="mitem">
            <img src="../assets/images/icon/common/mobile_message.svg" alt="">
            <div class="itext">
                {{i18nConst.MENU['MESSAGE'][$store.state.country]}}
            </div>
        </router-link>

        <router-link to="/user_info" tag="div" class="mitem">
            <img src="../assets/images/icon/common/mobile_user_info.svg" alt="">
            <div class="itext">
                {{i18nConst.MENU['USERINFO'][$store.state.country]}}
            </div>
        </router-link>
<!--        <router-link v-if="$store.state.isLogin" to="/" @click.native="logout"  tag="div" class="mitem">-->
<!--            <img src="../assets/images/icon/common/mobile_logout.svg" alt="">-->
<!--            <div class="itext">-->
<!--                {{i18nConst.MENU['LOGOUT'][$store.state.country]}}-->
<!--            </div>-->
<!--        </router-link>-->



    </div>
</template>

<script>
    import {logout} from "../network/userRequest";
    import {INIT_USERINFO} from "../store/mutation-types";
    import sportsConst from "../common/sportsConst";
    import i18nConst from "../common/i18nConst";

    export default {
        name: "MobileMenuItemsComp",
        data() {
            return {
                i18nConst:i18nConst,
            }
        },
        methods:{
            logout() {
                this.$swal({
                    title: i18nConst.COMMON['ISLOGOUT'][this.$store.state.country],
                    type: 'question',
                    confirmButtonText: i18nConst.COMMON['LOGOUT'][this.$store.state.country],
                }).then((res) => {
                    if (res.value) {
                        this.$store.commit(INIT_USERINFO);
                        window.sessionStorage.clear();
                        logout();
                        this.$router.replace("/main")
                    }
                })

            }
        }
    }
</script>

<style scoped>

    .menu-items {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        flex-wrap: nowrap;
        flex-direction: column;
        padding: 5px;
        color: #fff9ff;
    }
    .menu-items .mitem {
        width: 100%;
        text-align: left;
        padding: 0 10px;
        height: 50px;
        background-color: #092a4d;
        border-radius: 5px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        font-size: 14px;
        font-weight: bold;
        margin: 2px 0;
    }
    .menu-items .mitem img{
        height: 28px;
        padding: 3px 0;
    }
    .menu-items .mitem .itext{
        margin-left: 10px;
        color: #bbbbbb;
    }


    .menu-items .item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .menu-items .item .line{
        width: 100%;
        height: 1px;
        background-color: #45464b;
    }

    .menu-items .item .t {
        width: 100%;
        padding: 10px 0;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: #f0ab60;

    }

    .menu-items .item .g{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px 0;
    }
    .menu-items .item .g .g_item{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width:90px;
        height:90px;
        background-color: #33cdf7;
        font-size: 14px;
        margin: 0 2px;
        color: #2b2e37;
        font-weight: bold;
        border-radius: 3px;
    }
    .menu-items .item .g .g_item img{
        height: 28px;
        padding: 3px 0;
    }
    .menu-items .item .g .g_item .itext{
        padding: 8px 0;
    }



</style>