import http from "./axios/http";


export function getLobbyUrl(thirdPartyCode) {
  return http.get('/casino/get_lobby_url', {'thirdPartyCode':thirdPartyCode})
}
export function getGameUrl(gameId) {
  return http.get('/casino/get_game_url', {'gameId':gameId})
}
export function getGameList(thirdPartyCode) {
  return http.get('/casino/get_game_list', {'thirdPartyCode':thirdPartyCode})
}
export function getAccountBalance() {
  return http.get('/casino/get_account_balance', {})
}

export function addMemberCasinoCash(amount) {
  return http.get('/casino/add_member_casino_cash', {'amount':amount})
}
export function subjectMemberCasinoCash() {
  return http.get('/casino/subject_member_casino_cash')
}
export function subjectMemberCasinoCash2(amount) {
  return http.get('/casino/subject_member_casino_cash2?amount='+amount)
}
export function getCasinoMaintaining() {
  return http.get('/casino/get_casino_maintaining')
}