import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const Login = () => import('../views/user/Login');
const Register = () => import('../views/user/Register');
const Main = () => import('../views/user/Main');
const SportsOriginal = () => import('../views/sports/SportsOriginal');
const SportsGameResult = () => import('../views/sports/SportsGameResult');
const SportsBetInfo = () => import('../views/sports/SportsBetInfo');
const UserInfo = () => import('../views/user/UserInfo');
const CustomerCenter = () => import('../views/user/CustomerCenter');
const Board = () => import('../views/user/Board');
const Notice = () => import('../views/user/Notice');
const Event = () => import('../views/user/Event');
const BetRule = () => import('../views/user/BetRule');
const BoardDetail = () => import('../views/user/BoardDetail');
const EventDetail = () => import('../views/user/EventDetail');
const CulChek = () => import('../views/user/CulCheck');
const Rullet = () => import('../views/user/Rullet');
const Coupon = () => import('../views/user/Coupon');
const LiveTV = () => import('../views/user/LiveTV');
const Recharge = () => import('../views/user/Recharge');
const Exchange = () => import('../views/user/Exchange');
const Message = () => import('../views/user/Message');
const LeisureGame = () => import('../views/leisuregame/LeisureGame');
const LeisureGameBetInfo = () => import('../views/leisuregame/LeisrueGameBetInfo');
const LeisureGameResult = () => import('../views/leisuregame/LeisureGameResult');
const CasinoSlotGame = () => import('../views/casino/CasinoSlotGame');
const Bustabit = () => import('../views/bustabit/Bustabit');
const SportsInplay = () => import('../views/inplay/SportsInplay');
const RuleUse = () => import('../views/user/RuleUse');
const RuleSite = () => import('../views/user/RuleSite');
const RuleUserInfo = () => import('../views/user/RuleUserInfo');
const RuleEmail = () => import('../views/user/RuleEmail');
const RulePartner = () => import('../views/user/RulePartner');
const RuleQA = () => import('../views/user/RuleQA');


const routes = [
    {
        path: '/',
        redirect: '/main'
    },
    {
        path: '/rule_use',
        name: 'rule_use',
        component: RuleUse,
        meta: {
            title: '이용약관'
        }
    },
    {
        path: '/rule_site',
        name: 'rule_site',
        component: RuleSite,
        meta: {
            title: '운영정책'
        }
    },
    {
        path: '/rule_userinfo',
        name: 'rule_userinfo',
        component: RuleUserInfo,
        meta: {
            title: '개인정보처리방침'
        }
    },
    {
        path: '/rule_email',
        name: 'rule_email',
        component: RuleEmail,
        meta: {
            title: '이메일무단수집거부'
        }
    },
    {
        path: '/rule_partner',
        name: 'rule_partner',
        component: RulePartner,
        meta: {
            title: '제휴문의'
        }
    },
    {
        path: '/rule_qa',
        name: 'rule_qa',
        component: RuleQA,
        meta: {
            title: 'Q&A'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: '로그인'
        }
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            title: '회원가입'
        }
    },
    {
        path: '/main',
        name: 'main',
        component: Main,
        meta: {
            title: '메인'
        }
    },
    {
        path: '/sports',
        name: 'sports',
        component: SportsOriginal,
        meta: {
            title: '스포츠'
        }
    },
    {
        path: '/sports_inplay',
        name: 'sports_inplay',
        component: SportsInplay,
        meta: {
            title: 'Inplay'
        }
    },
    {
        path: '/sports_result',
        name: 'sports_result',
        component: SportsGameResult,
        meta: {
            title: 'Sports경기결과'
        }
    },
    {
        path: '/leisure_result',
        name: 'leisure_result',
        component: LeisureGameResult,
        meta: {
            title: 'Sports경기결과'
        }
    },
    {
        path: '/sports_betinfo',
        name: 'sports_betinfo',
        component: SportsBetInfo,
        meta: {
            title: '베팅내역(Sports)'
        }
    },
    {
        path: '/leisure_betinfo',
        name: 'leisure_betinfo',
        component: LeisureGameBetInfo,
        meta: {
            title: '베팅내역(Mini)'
        }
    },
    {
        path: '/customer_center',
        name: 'customer_center',
        component: CustomerCenter,
        meta: {
            title: '1:1문의'
        }
    },
    {
        path: '/board',
        name: 'board',
        component: Board,
        meta: {
            title: '게시판'
        }
    },
    {
        path: '/board_detail/:id',
        name: 'boardDetail',
        component: BoardDetail,
        meta: {
            title: '게시판'
        }
    },
    {
        path: '/notice',
        name: 'notice',
        component: Notice,
        meta: {
            title: '공지사항'
        }
    },
    {
        path: '/betrule',
        name: 'betrule',
        component: BetRule,
        meta: {
            title: '베팅규정'
        }
    },
    {
        path: '/event',
        name: 'event',
        component: Event,
        meta: {
            title: '이벤트'
        }
    },
    {
        path: '/event_detail/:id',
        name: 'eventDetail',
        component: EventDetail,
        meta: {
            title: '이벤트'
        }
    },
    {
        path: '/recharge',
        name: 'recharege',
        component: Recharge,
        meta: {
            title: 'COIN IN'
        }
    },
    {
        path: '/exchange',
        name: 'exchange',
        component: Exchange,
        meta: {
            title: 'COIN OUT'
        }
    },
    {
        path: '/message',
        name: 'message',
        component: Message,
        meta: {
            title: '쪽지'
        }
    },
    {
        path: '/user_info',
        name: 'user_info',
        component: UserInfo,
        meta: {
            title: '내정보'
        }
    },
    {
        path: '/casino',
        name: 'CasinoSlotGame',
        component: CasinoSlotGame,
        meta: {
            title: 'Casino&SlotGame'
        }
    },
    {
        path: '/livetv',
        name: 'livetv',
        component: LiveTV,
        meta: {
            title: 'Live TV'
        }
    },
    {
        path: '/bustabit',
        name: 'bustabit',
        component: Bustabit,
        meta: {
            title: '그래프'
        }
    },
    {
        path: '/culcheck',
        name: 'culcheck',
        component: CulChek,
        meta: {
            title: '출석체크'
        }
    },
    {
        path: '/rullet',
        name: 'rullet',
        component: Rullet,
        meta: {
            title: '룰렛'
        }
    },
    {
        path: '/coupon',
        name: 'coupon',
        component: Coupon,
        meta: {
            title: '쿠폰'
        }
    },
    {
        path: '/leisure',
        name: 'leisure',
        component: LeisureGame,
        children: [
            {
                path: '/',
                redirect: 'bet365_premiership'
            },
            {
                path: 'powersadali',
                name: 'leisure_detail_powersadali',
                component: resolve => require(['../views/leisuregame/children/PowerSadali'], resolve),
                meta: {
                    title: '파워사다리'
                }
            },
            {
                path: 'speedkino',
                name: 'leisure_detail_speedkino',
                component: resolve => require(['../views/leisuregame/children/SpeedKino'], resolve),
                meta: {
                    title: '스피드키노'
                }
            },
            {
                path: 'kinosadali',
                name: 'leisure_detail_kinosadali',
                component: resolve => require(['../views/leisuregame/children/KinoSadali'], resolve),
                meta: {
                    title: '키노 사다리'
                }
            },
            {
                path: 'nameddalidali',
                name: 'leisure_detail_nameddalidali',
                component: resolve => require(['../views/leisuregame/children/NamedDalidali'], resolve),
                meta: {
                    title: '네임드 다리다리'
                }
            },
            {
                path: 'namedsadali',
                name: 'leisure_detail_namedsadali',
                component: resolve => require(['../views/leisuregame/children/NamedSadali'], resolve),
                meta: {
                    title: '네임드 사다리'
                }
            },
            {
                path: 'namedspeedsadali',
                name: 'leisure_detail_namedspeedsadali',
                component: resolve => require(['../views/leisuregame/children/NamedSpeedSadali'], resolve),
                meta: {
                    title: '네임드 스피드 사다리'
                }
            },
            {
                path: 'namedsnail',
                name: 'leisure_detail_namedsnail',
                component: resolve => require(['../views/leisuregame/children/NamedSnail'], resolve),
                meta: {
                    title: '네임드 달팽이'
                }
            },
            {
                path: 'fx1m',
                name: 'leisure_detail_fx1m',
                component: resolve => require(['../views/leisuregame/children/Fx1m'], resolve),
                meta: {
                    title: 'FX 1M'
                }
            },
            {
                path: 'fx2m',
                name: 'leisure_detail_fx2m',
                component: resolve => require(['../views/leisuregame/children/Fx2m'], resolve),
                meta: {
                    title: 'FX 2M'
                }
            },
            {
                path: 'fx3m',
                name: 'leisure_detail_fx3m',
                component: resolve => require(['../views/leisuregame/children/Fx3m'], resolve),
                meta: {
                    title: 'FX 3M'
                }
            },
            {
                path: 'fx4m',
                name: 'leisure_detail_fx4m',
                component: resolve => require(['../views/leisuregame/children/Fx4m'], resolve),
                meta: {
                    title: 'FX 4M'
                }
            },
            {
                path: 'fx5m',
                name: 'leisure_detail_fx5m',
                component: resolve => require(['../views/leisuregame/children/Fx5m'], resolve),
                meta: {
                    title: 'FX 5M'
                }
            },
            {
                //Bet365SoccerPremiership
                path: 'bet365_premiership',
                name: 'leisure_detail_bet365_premiership',
                component: resolve => require(['../views/leisuregame/children/Bet365SoccerPremiership'], resolve),
                meta: {
                    title: 'Bet365 프리미어'
                }
            },
            {
                //Bet365SoccerSuperLeague
                path: 'bet365_superleague',
                name: 'leisure_detail_bet365_superleague',
                component: resolve => require(['../views/leisuregame/children/Bet365SoccerSuperLeague'], resolve),
                meta: {
                    title: 'Bet365 슈퍼리그'
                }
            },
            {
                //Bet365SoccerEuroCup
                path: 'bet365_eurocup',
                name: 'leisure_detail_bet365_eurocup',
                component: resolve => require(['../views/leisuregame/children/Bet365SoccerEuroCup'], resolve),
                meta: {
                    title: 'Bet365 유로컵'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'bet365_worldcup',
                name: 'leisure_detail_bet365_worldcup',
                component: resolve => require(['../views/leisuregame/children/Bet365SoccerWorldCup'], resolve),
                meta: {
                    title: 'Bet365 월드컵'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'bet365_goldenhill',
                name: 'leisure_detail_bet365_goldenhill',
                component: resolve => require(['../views/leisuregame/children/Bet365DogRaceGoldenHill'], resolve),
                meta: {
                    title: 'Bet365 개경주 골든힐'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'bet365_hillside',
                name: 'leisure_detail_bet365_hillside',
                component: resolve => require(['../views/leisuregame/children/Bet365DogRaceHillSide'], resolve),
                meta: {
                    title: 'Bet365 개경주 힐사이드'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'powerball',
                name: 'leisure_detail_powerball',
                component: resolve => require(['../views/leisuregame/children/Powerball'], resolve),
                meta: {
                    title: '파워볼'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'powerball_lotto',
                name: 'leisure_detail_powerball_lotto',
                component: resolve => require(['../views/leisuregame/children/PowerballLotto'], resolve),
                meta: {
                    title: '파워볼 로또'
                }
            }
            ,
            {
                //크라운 섯다
                path: 'sutda',
                name: 'leisure_detail_sutda',
                component: resolve => require(['../views/leisuregame/children/CrownSutda'], resolve),
                meta: {
                    title: '크라운 섯다'
                }
            },
            {
                //크라운 나인볼
                path: 'nineball',
                name: 'leisure_detail_nineball',
                component: resolve => require(['../views/leisuregame/children/CrownNineBall'], resolve),
                meta: {
                    title: '크라운 섯다'
                }
            },
            {
                //로투스 바카라
                path: 'lotus_bakara',
                name: 'leisure_detail_lotus_bakara',
                component: resolve => require(['../views/leisuregame/children/LotusBakara'], resolve),
                meta: {
                    title: '로투스 바카라'
                }
            },
            {
                //로투스 바카라
                path: 'lotus_oddeven',
                name: 'leisure_detail_lotus_oddeven',
                component: resolve => require(['../views/leisuregame/children/LotusOddEven'], resolve),
                meta: {
                    title: '로투스 홀짝'
                }
            },
            {
                //로투스 바카라
                path: 'lotus_dragontiger',
                name: 'leisure_detail_lotus_dragontiger',
                component: resolve => require(['../views/leisuregame/children/LotusDragonTiger'], resolve),
                meta: {
                    title: '로투스 드래곤타이거'
                }
            },
            {
                //sky speed 바카라
                path: 'sky_speed_bakara',
                name: 'leisure_detail_sky_speed_bakara',
                component: resolve => require(['../views/leisuregame/children/SkySpeedBakara'], resolve),
                meta: {
                    title: '스카이파크 Speed 바카라'
                }
            },
            {
                //sky 홀짝
                path: 'sky_oddeven',
                name: 'leisure_detail_sky_oddeven',
                component: resolve => require(['../views/leisuregame/children/SkyOddEven'], resolve),
                meta: {
                    title: '스카이파크 홀짝'
                }
            },
            {
                //sky 주사위
                path: 'sky_dice',
                name: 'leisure_detail_sky_dice',
                component: resolve => require(['../views/leisuregame/children/SkyDice'], resolve),
                meta: {
                    title: '스카이파크 주사위'
                }
            }


        ],
        meta: {
            title: '미니게임'
        }
    }
    
]

const router = new VueRouter({
    /* mode: 'history',*/
    routes,
    linkExactActiveClass: 'acvice_menu',
})

/*method*/
const contains = function (needle, arr) {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] === needle) return true;
    }
    return false;
}
router.beforeEach((to, from, next) => {
    next()
});
const VueRouterPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}


export default router
