import sportsConst from "../common/sportsConst";
import i18nConst from "../common/i18nConst";

export default {
    isMaintaining: false,
    maintainingMessage: '',
    ismobile: false,
    isLogin: false,
    isShowLoginPanel: false,
    isShowRegisterPanel: false,
    isShowTopbar: false,
    isLoading: false,
    isInplayLoading: false,
    iild: false,
    displayPostion: sportsConst.GAME_DISPLAYPOSITION_1x2,
    userInfo: {
        nickname: '',
        cash: 0,
        userPoint: 0,
        casinocash: 0,
        rank: {rankName: ''},
        myReferrer: {},
        referreCode: {},
        subordinateUsers: []
    },
    uncheckedMessageCount: 0,
    commonConfig: {
        rankConfig: {betMinLimitCash: 0, betMaxLimitCash: 0},
        bonusConfig: {},
        crossRuleconfig: {},
        hlv:0
    },
    sportsCartItems: [],
    inplayCartItems: [],
    sportsOriginal: [],
    sportsLive: [],
    minigames: {},
    selectedGameType: sportsConst.GAME_TYPE_ALL,
    selectedLeagueList: [],
    currentLeisureGameComp: -1,
    currentLeisureGameID: -1,
    popups: [],
    ethPrice: -1,
    staPrice: -1,
    usdtPrice: -1,
    ethBalance: 0,
    staBalance: 0,
    usdtBalance: 0,
    ethWallet: '',
    staWallet: '',
    usdtWallet: '',
    country: i18nConst.EN,
}