export default {
    KR: "kr",
    JP: "jp",
    EN: "en",
    FRA: "fra",
    SPA: "spa",
    //MENU
    MENU: {
        "SPORTS": {
            "kr": "스포츠",
            "jp": "スポーツ",
            "en": "SPORTS",
            "fra": "SPORTS",
            "spa": "SPORTS"
        },
        "INPLAY": {
            "kr": "인플레이",
            "jp": "INPLAY",
            "en": "INPLAY",
            "fra": "INPLAY",
            "spa": "INPLAY"
        },
        "CASINO": {
            "kr": "소셜카지노",
            "jp": "CASINO",
            "en": "CASINO",
            "fra": "CASINO",
            "spa": "CASINO"
        },
        "VSPORTS": {
            "kr": "가상스포츠",
            "jp": "V-SPORTS",
            "en": "V-SPORTS",
            "fra": "V-SPORTS",
            "spa": "V-SPORTS"
        },
        "BETLIST": {
            "kr": "베팅내역",
            "jp": "BETLIST",
            "en": "BETLIST",
            "fra": "BETLIST",
            "spa": "BETLIST"
        },

        "QUESTION": {
            "kr": "1:1문의",
            "jp": "QUESTION",
            "en": "QUESTION",
            "fra": "QUESTION",
            "spa": "QUESTION"
        },
        "NOTICE": {
            "kr": "공지사항",
            "jp": "NOTICE",
            "en": "NOTICE",
            "fra": "NOTICE",
            "spa": "NOTICE"
        },
        "COININ": {
            "kr": "COIN IN",
            "jp": "COIN IN",
            "en": "COIN IN",
            "fra": "COIN IN",
            "spa": "COIN IN"
        },
        "COINOUT": {
            "kr": "COIN OUT",
            "jp": "COIN OUT",
            "en": "COIN OUT",
            "fra": "COIN OUT",
            "spa": "COIN OUT"
        },
        "MESSAGE": {
            "kr": "쪽지",
            "jp": "MESSAGE",
            "en": "MESSAGE",
            "fra": "MESSAGE",
            "spa": "MESSAGE"
        },
        "EVENT": {
            "kr": "이벤트",
            "jp": "EVENT",
            "en": "EVENT",
            "fra": "EVENT",
            "spa": "EVENT"
        },
        "USERINFO": {
            "kr": "유저정보",
            "jp": "USER INFO",
            "en": "USER INFO",
            "fra": "USER INFO",
            "spa": "USER INFO"
        },
        "TERMS_OF_SERVICE": {
            "kr": "이용약관",
            "jp": "利用規約",
            "en": "Terms of service",
            "fra": "Conditions d'utilisation",
            "spa": "Términos de servicio"
        },
        "OPERATION_POLICY": {
            "kr": "운영정책",
            "jp": "運営政策",
            "en": "operation policy",
            "fra": "politique de fonctionnement",
            "spa": "política de operación"
        },
        "PRIVACY_POLICY": {
            "kr": "개인정보처리방침",
            "jp": "個人情報処理方針",
            "en": "privacy policy",
            "fra": "politique de confidentialité",
            "spa": "política de privacidad"
        },
        "EMAIL_COLLECTION": {
            "kr": "이메일무단수집거부",
            "jp": "電子メール無断収集拒否",
            "en": "Rejection of unauthorized e-mail collection",
            "fra": "Refus de la collecte non autorisée d'e-mails",
            "spa": "Rechazo de la recopilación de correo electrónico no autorizado"
        },
        "PARTNER_INQUIRY": {
            "kr": "제휴문의",
            "jp": "提携お問い合わせ",
            "en": "Partnership inquiry",
            "fra": "Demande de partenariat",
            "spa": "Consulta de asociación"
        }
        ,
        "QA": {
            "kr": "Q&A",
            "jp": "Q&A",
            "en": "Q&A",
            "fra": "Q&A",
            "spa": "Q&A"
        },
        "ATTENDANCE": {
            "kr": "출석부",
            "jp": "",
            "en": "Attendance",
            "fra": "",
            "spa": ""
        },


    },
    //common
    COMMON: {

        "HANDICAP_2WAY": {
            "kr": "핸디캡 2WAY",
            "jp": "ハンディキャップ 2WAY",
            "en": "Handicap 2WAY",
            "fra": "Handicap 2WAY",
            "spa": "Handicap 2WAY"
        },
        "HANDICAP_3WAY": {
            "kr": "핸디캡 3WAY",
            "jp": "ハンディキャップ 3WAY",
            "en": "Handicap 3WAY",
            "fra": "Handicap 3WAY",
            "spa": "Handicap 3WAY"


        },

        "SCORE": {
            "kr": "스코어",
            "jp": "スコア",
            "en": "Score",
            "fra": "score",
            "spa": "puntaje"
        },
        "YES": {
            "kr": "예",
            "jp": "はい",
            "en": "Yes",
            "fra": "Oui",
            "spa": "Sí"
        },
        "NO": {
            "kr": "아니요",
            "jp": "いいえ",
            "en": "No",
            "fra": "non",
            "spa": "no"
        },
        "LOGIN": {
            "kr": "로그인",
            "jp": "ログイン",
            "en": "login",
            "fra": "connexion",
            "spa": "acceso"
        },
        "REGISTE": {
            "kr": "회원가입",
            "jp": "会員登録",
            "en": "Sign Up",
            "fra": "S'inscrire",
            "spa": "Inscribirse"
        },
        "LOGOUT": {
            "kr": "로그아웃",
            "jp": "ログアウト",
            "en": "Log out",
            "fra": "Se déconnecter",
            "spa": "Cerrar sesión"
        },
        "ISLOGOUT": {
            "kr": "로그아웃 하시겠습니까 ?",
            "jp": "ログアウトしますか？",
            "en": "Are you sure you want to log out ?",
            "fra": "Êtes-vous sûr de vouloir vous déconnecter ?",
            "spa": "¿Está seguro de que desea cerrar la sesión?"
        },
        "TIE": {
            "kr": "무승부",
            "jp": "TIE",
            "en": "Tie",
            "fra": "cravate",
            "spa": "Corbata"
        },
        "HOME": {
            "kr": "홈",
            "jp": "ホーム",
            "en": "Home",
            "fra": "domicile",
            "spa": "casa"
        },
        "AWAY": {
            "kr": "원정",
            "jp": "Away",
            "en": "Away",
            "fra": "équipe extérieure",
            "spa": "equipo de fuera"
        },
        "HALF1": {
            "kr": "전반전",
            "jp": "前半",
            "en": "First half",
            "fra": "première moitié",
            "spa": "primera mitad"
        },
        "HALF2": {
            "kr": "후반전",
            "jp": "後半",
            "en": "Second half",
            "fra": "Deuxième partie",
            "spa": "segunda mitad"
        },
        "INNING": {
            "kr": "이닝",
            "jp": "イニング",
            "en": "Ining",
            "fra": "manches",
            "spa": "entrada"
        },
        "OVER": {
            "kr": "오버",
            "jp": "オーバー",
            "en": "Over",
            "fra": "terminé",
            "spa": "terminado"
        },
        "UNDER": {
            "kr": "언더",
            "jp": "アンダー",
            "en": "Under",
            "fra": "en dessous de",
            "spa": "por debajo"
        },
        "ODD_EVEN": {
            "kr": "홀짝",
            "jp": "おっぱい",
            "en": "Odd/Even",
            "fra": "reniflement",
            "spa": "lloriqueando"
        },
        "ODD": {
            "kr": "홀수",
            "jp": "奇数",
            "en": "odd",
            "fra": "nombre impair",
            "spa": "número impar"
        },
        "EVEN": {
            "kr": "짝수",
            "jp": "偶数",
            "en": "EVEN",
            "fra": "Même",
            "spa": "Incluso"
        },
        "HOMETEAM": {
            "kr": "홈팀",
            "jp": "ホームチーム",
            "en": "home team",
            "fra": "équipe à domicile",
            "spa": "equipo local"
        },
        "AWAYTEAM": {
            "kr": "원정팀",
            "jp": "遠征チーム",
            "en": "away team",
            "fra": "équipe extérieure",
            "spa": "equipo de fuera"
        },
        "SELECTED": {
            "kr": "선택",
            "jp": "選択",
            "en": "Selected",
            "fra": "Sélectionner",
            "spa": "Seleccione"
        },
        "DATE": {
            "kr": "일자",
            "jp": "日にち",
            "en": "Date",
            "fra": "Date",
            "spa": "Fecha"
        },
        "CONTENT": {
            "kr": "내용",
            "jp": "内容",
            "en": "content",
            "fra": "détail",
            "spa": "detalle"
        },
        "AMOUNT": {
            "kr": "금액",
            "jp": "金額",
            "en": "Amount",
            "fra": "Prix",
            "spa": "Precio"
        },
        "AMOUNT2": {
            "kr": "수량",
            "jp": "Amount",
            "en": "Amount",
            "fra": "Amount",
            "spa": "Amount"
        },
        "STATUS": {
            "kr": "상태",
            "jp": "状態",
            "en": "Status",
            "fra": "Etat",
            "spa": "Expresar"
        },
        "UNIDENTIFIED": {
            "kr": "미확인",
            "jp": "未確認",
            "en": "Unidentified",
            "fra": "non identifié",
            "spa": "no identificado"
        },
        "CHECKING": {
            "kr": "확인중",
            "jp": "確認中",
            "en": "Checking",
            "fra": "Vérification",
            "spa": "Comprobación"
        },
        "FAILURE": {
            "kr": "실패",
            "jp": "失敗",
            "en": "failure",
            "fra": "échec",
            "spa": "falla"
        },

        "HIT_SPECIAL": {
            "kr": "적특",
            "jp": "ヒット特例",
            "en": "Hit special",
            "fra": "coup spécial",
            "spa": "golpe especial"
        },
        "CANCELL": {
            "kr": "취소",
            "jp": "キャンセル",
            "en": "Cancell",
            "fra": "annulation",
            "spa": "cancelación"
        },
        "DELETE": {
            "kr": "삭제",
            "jp": "削除",
            "en": "Delete",
            "fra": "effacer",
            "spa": "Eliminar"
        },
        "CLOSE": {
            "kr": "닫기",
            "jp": "閉じる",
            "en": "Close",
            "fra": "proche",
            "spa": "Cerrar"
        },
        "REQUST_COMPLETED": {
            "kr": "신청완료",
            "jp": "申請完了",
            "en": "request completed",
            "fra": "Demande terminée",
            "spa": "Solicitud completada"
        },
        "CONVERSION_SUCCESS": {
            "kr": "전환성공",
            "jp": "転換成功",
            "en": "Conversion Success",
            "fra": "succès de conversion",
            "spa": "éxito de conversión"
        },
        "CONVERSION_FAILED": {
            "kr": "전환실패",
            "jp": "移行失敗",
            "en": "Conversion failed",
            "fra": "échec de conversion",
            "spa": "error de conversión"
        },
        "CONVERSION_FAILED2": {
            "kr": "전환금액을 입력하세요 ",
            "jp": "input change amount",
            "en": "input change amount",
            "fra": "input change amount",
            "spa": "input change amount"
        },
        "CAN_DELETE_MSG_1": {
            "kr": "삭제하시겠습니까",
            "jp": "削除しますか？",
            "en": "do you want to delete?",
            "fra": "voulez-vous supprimer?",
            "spa": "Quieres borrar?"
        },
        "CHECK_ALL": {
            "kr": "전체확인",
            "jp": "全体確認",
            "en": "check all",
            "fra": "vérifie tout",
            "spa": "Comprobar todo"
        },
        "DELETE_ALL": {
            "kr": "전체삭제",
            "jp": "すべて削除",
            "en": "delete all",
            "fra": "Supprimer tout",
            "spa": "Eliminar todos"
        },
        "CONFIRM": {
            "kr": "확인",
            "jp": "確認",
            "en": "Confirm",
            "fra": "Confirmer",
            "spa": "Confirmar"
        },
        "ANSWER_COMPLETE": {
            "kr": "답변완료",
            "jp": "回答完了",
            "en": "Answer complete",
            "fra": "Réponse terminée",
            "spa": "respuesta completa"
        },
        "WAITING_FOR_REPLY": {
            "kr": "답변대기",
            "jp": "回答待ち",
            "en": "Waiting for reply",
            "fra": "attendre une réponse",
            "spa": "esperando respuesta"
        },
        "WRITING": {
            "kr": "글쓰기",
            "jp": "書く",
            "en": "writing",
            "fra": "en écrivant",
            "spa": "escritura"
        },
        "REGISTRATION": {
            "kr": "문의등록",
            "jp": "お問い合わせ登録",
            "en": "Inquiry registration",
            "fra": "Demande d'inscription",
            "spa": "registro de consulta"
        },
        "MINI_GAME": {
            "kr": "미니게임",
            "jp": "ミニゲーム",
            "en": "MINI GAME",
            "fra": "mini jeu",
            "spa": "mini juego"
        },
        "RESULT": {
            "kr": "결과",
            "jp": "結果",
            "en": "Result",
            "fra": "résultat",
            "spa": "resultado"
        },
        "ROUND_D": {
            "kr": "회차",
            "jp": "ラウンド",
            "en": "Round",
            "fra": "tour",
            "spa": "redondo"
        },
        "ROUND": {
            "kr": "당일회차",
            "jp": "ラウンド",
            "en": "Round",
            "fra": "tour",
            "spa": "redondo"
        },
        "STOP_WATCHING_TODAY": {
            "kr": "오늘하루 그만보기",
            "jp": "今日一日をやめる",
            "en": "stop watching today",
            "fra": "arrêter de regarder aujourd'hui",
            "spa": "deja de mirar hoy"
        },
        "CROSS": {
            "kr": "크로스",
            "jp": "クロス",
            "en": "Cross",
            "fra": "croix",
            "spa": "equis"
        },
        "SPECIAL": {
            "kr": "스페셜",
            "jp": "スペシャル",
            "en": "Special",
            "fra": "spécial",
            "spa": "especial"
        },
        "TYPE": {
            "kr": "종목",
            "jp": "種目",
            "en": "Type",
            "fra": "un événement",
            "spa": "evento"
        },
        "SPORTS_LEAGUE": {
            "kr": "종목 & 리그",
            "jp": "種目＆リーグ",
            "en": "Sports & Leagues",
            "fra": "Sports et ligues",
            "spa": "Deportes y ligas"
        },
        "POPULAR_LEAGUE": {
            "kr": "인기리그",
            "jp": "人気リーグ",
            "en": "Popular League",
            "fra": "ligue populaire",
            "spa": "liga popular"
        },
        "LEAGUE": {
            "kr": "리그",
            "jp": "リーグ",
            "en": "League",
            "fra": "Ligue",
            "spa": "Liga"
        },

        "BET_CART": {
            "kr": "카트",
            "jp": "カート",
            "en": "Cart",
            "fra": "Chariot",
            "spa": "carro"
        },
        "HAND_AND_OVERUNDER": {
            "kr": "핸디/오언",
            "jp": "ハンディ/オーアン",
            "en": "Handicap/Overunder",
            "fra": "Pratique/Owen",
            "spa": "práctico/owen"
        },
        "LIVE": {
            "kr": "라이브",
            "jp": "ライブ",
            "en": "Live",
            "fra": "habitent",
            "spa": "En Vivo"
        },
        "1_3N": {
            "kr": "1-3이닝",
            "jp": "1-3イニング",
            "en": "1-3 Inning",
            "fra": "1-3 manches",
            "spa": "1-3 entradas"
        },
        "1_5N": {
            "kr": "1-5이닝",
            "jp": "1-5イニング",
            "en": "1-5 Inning",
            "fra": "1-5 manches",
            "spa": "1-5 entradas"
        },
        "1_7N": {
            "kr": "1-7이닝",
            "jp": "1-7イニング",
            "en": "1-7 Inning",
            "fra": "1-7 manches",
            "spa": "1-7 entradas"
        },
        "1_N": {
            "kr": "1 이닝",
            "jp": "1イニング",
            "en": "1 Inning",
            "fra": "1 manche",
            "spa": "1 entrada"
        },
        "2_N": {
            "kr": "2 이닝",
            "jp": "2イニング",
            "en": "2 Inning",
            "fra": "2 manche",
            "spa": "2 entrada"
        },
        "3_N": {
            "kr": "3 이닝",
            "jp": "3イニング",
            "en": "3 Inning",
            "fra": "3 manche",
            "spa": "3 entrada"
        },
        "4_N": {
            "kr": "4 이닝",
            "jp": "4イニング",
            "en": "4 Inning",
            "fra": "4 manche",
            "spa": "4 entrada"
        },
        "5_N": {
            "kr": "5 이닝",
            "jp": "5イニング",
            "en": "5 Inning",
            "fra": "5 manche",
            "spa": "5 entrada"
        },
        "6_N": {
            "kr": "6 이닝",
            "jp": "6イニング",
            "en": "6 Inning",
            "fra": "6 manche",
            "spa": "6 entrada"
        },
        "7_N": {
            "kr": "7 이닝",
            "jp": "7イニング",
            "en": "7 Inning",
            "fra": "7 manche",
            "spa": "7 entrada"
        },
        "8_N": {
            "kr": "8 이닝",
            "jp": "8イニング",
            "en": "8 Inning",
            "fra": "8 manche",
            "spa": "8 entrada"
        },
        "1_Q": {
            "kr": "1Q",
            "jp": "1Q",
            "en": "1Q",
            "fra": "1Q",
            "spa": "1Q"
        },
        "2_Q": {
            "kr": "2Q",
            "jp": "2Q",
            "en": "2Q",
            "fra": "2Q",
            "spa": "2Q"
        },
        "3_Q": {
            "kr": "3Q",
            "jp": "3Q",
            "en": "3Q",
            "fra": "3Q",
            "spa": "3Q"
        },
        "4_Q": {
            "kr": "4Q",
            "jp": "4Q",
            "en": "4Q",
            "fra": "4Q",
            "spa": "4Q"
        },
        "1_SET": {
            "kr": "1세트",
            "jp": "1Set",
            "en": "1Set",
            "fra": "1Set",
            "spa": "1Set"
        },
        "2_SET": {
            "kr": "2세트",
            "jp": "2Set",
            "en": "2Set",
            "fra": "2Set",
            "spa": "2Set"
        },
        "3_SET": {
            "kr": "3세트",
            "jp": "3Set",
            "en": "3Set",
            "fra": "3Set",
            "spa": "3Set"
        },
        "4_SET": {
            "kr": "4세트",
            "jp": "4Set",
            "en": "4Set",
            "fra": "4Set",
            "spa": "4Set"
        },
        "1_P": {
            "kr": "1Period",
            "jp": "1Period",
            "en": "1Period",
            "fra": "1Period",
            "spa": "1Period"
        },
        "2_P": {
            "kr": "2Period",
            "jp": "2Period",
            "en": "2Period",
            "fra": "2Period",
            "spa": "2Period"
        },
        "3_P": {
            "kr": "3Period",
            "jp": "3Period",
            "en": "3Period",
            "fra": "3Period",
            "spa": "3Period"
        },


    },
    //종목
    GAME_TYPE: {
        "ALL": {
            "kr": "전체",
            "jp": "すべて",
            "en": "ALL",
            "fra": "TOUTE",
            "spa": "TODOS"
        },
        "SOCCER": {
            "kr": "축구",
            "jp": "サッカー",
            "en": "Soccer",
            "fra": "Le football",
            "spa": "Fútbol"
        },
        "BASKETBALL": {
            "kr": "농구",
            "jp": "バスケットボール",
            "en": "Basketball",
            "fra": "Basketball",
            "spa": "Baloncesto"
        },
        "BASEBALL": {
            "kr": "야구",
            "jp": "野球",
            "en": "Baseball",
            "fra": "Base-ball",
            "spa": "Béisbol"
        },
        "VOLLEYBALL": {
            "kr": "배구",
            "jp": "バレーボール",
            "en": "Volleyball",
            "fra": "Volley-ball",
            "spa": "Vóleibol"
        },
        "HOCKEY": {
            "kr": "하키",
            "jp": "ホッケー",
            "en": "Hockey",
            "fra": "le hockey",
            "spa": "Hockey"
        },
        "ESPORTS": {
            "kr": "E-sports",
            "jp": "E-sports",
            "en": "E-sports",
            "fra": "E-sports",
            "spa": "E-sports"
        },
        "FOOTBALL": {
            "kr": "미식축구",
            "jp": "アメリカンフットボール",
            "en": "Football",
            "fra": "Football",
            "spa": "fútbol americano"
        },
        "TENNIS": {
            "kr": "테니스",
            "jp": "テニス",
            "en": "Tennis",
            "fra": "Tennis",
            "spa": "Tenis"
        }

    },
    //카지노관련
    CASINO: {
        1: {
            "kr": " 카지노 점검중입니다",
            "jp": " カジノのメンテナンス中です",
            "en": " Casino is under maintenance.",
            "fra": " Le casino est en maintenance.",
            "spa": " El casino está en mantenimiento."
        },
        "CASINO_CASH_CONVERSION": {
            "kr": "카지노 캐쉬 전환",
            "jp": "カジノキャッシュの切り替え",
            "en": "Casino Cash Conversion",
            "fra": "Conversion en espèces du casino",
            "spa": "Conversión de efectivo de casino"
        },
        "CASINO_CASH": {
            "kr": "카지노캐쉬",
            "jp": " カジノキャッシュ",
            "en": "Casino cash",
            "fra": " argent comptant",
            "spa": " dinero en efectivo"
        },
        "CASINO_SLOT": {
            "kr": "카지노&슬롯",
            "jp": " カジノ＆スロット",
            "en": "Casino&Slot",
            "fra": " Casino et machines à sous",
            "spa": " Casino y tragamonedas"
        },
        "SPORTS_CASH_CONVERSION": {
            "kr": "스포츠 캐쉬 전환",
            "jp": "スポーツキャッシュの切り替え",
            "en": "Sports Cash Conversion",
            "fra": "Conversion de trésorerie sportive",
            "spa": "Conversión de efectivo deportivo"
        },
        "CASINO_CASH_CONVERSION_MSG_1": {
            "kr": "현재 카지노에 보유한 금액 전체를 전환합니다",
            "jp": "現在のカジノに保持している金額全体を切り替えます",
            "en": "Convert the entire amount you currently have in the casino to Sports Cash",
            "fra": "Convertit le montant total que vous avez actuellement dans le casino",
            "spa": "Convierte la cantidad total que tiene actualmente en el casino"
        },
        "SLOT_GAMES": {
            "kr": "슬롯게임",
            "jp": "スロットゲーム",
            "en": "SLOT GAMES",
            "fra": "jeu de machine à sous",
            "spa": "tragamonedas"
        },
        "PROGRAMMATIC_SLOT": {
            "kr": "프로그매틱 슬롯",
            "jp": "プログマティックスロット",
            "en": "Programmatic slots",
            "fra": "créneaux programmatiques",
            "spa": "tragamonedas programáticas"
        },
        "RTG_SLOT": {
            "kr": "RTG 슬롯",
            "jp": "RTGスロット",
            "en": "RTG slots",
            "fra": "Fente RTG",
            "spa": "ranura RTG"
        },
        "STOKRAT_SLOT": {
            "kr": "스토크랏 슬롯",
            "jp": "ストクラットスロット",
            "en": "Stokrat slots",
            "fra": "fentes stokrat",
            "spa": "Tragamonedas stokrat"
        },
        "CQ9_SLOT": {
            "kr": "CQ9 슬롯",
            "jp": "CQ9スロット",
            "en": "CQ9 slots",
            "fra": "Emplacement CQ9",
            "spa": "Ranura CQ9"
        },
        "BGAMES_SLOT": {
            "kr": "B게이밍 슬롯",
            "jp": "Bゲーミングスロット",
            "en": "B-GAMES slots",
            "fra": "Machine à sous de jeu B",
            "spa": "Tragamonedas de juego B"
        },
        "AG_SLOT": {
            "kr": "AG 슬롯",
            "jp": "AGスロット",
            "en": "AG slots",
            "fra": "Fente AG",
            "spa": "ranura AG"
        },
        "BBIN_SLOT": {
            "kr": "BBIN 슬롯",
            "jp": "BBINスロット",
            "en": "BBIN slots",
            "fra": "Machines à sous BBIN",
            "spa": "Tragamonedas BBIN"
        },
        "EVOPLAY_SLOT": {
            "kr": "에보플레이 슬롯",
            "jp": "エボプレイスロット",
            "en": "Evoplay slots",
            "fra": "machines à sous evoplay",
            "spa": "tragamonedas evoplay"
        },
        "CASINO_CASH_CONVERSION_MSG_2": {
            "kr": "카지노캐쉬를 스포츠캐쉬로 전환 하시겠습니까?",
            "jp": "カジノキャッシュをスポーツキャッシュに切り替えますか？",
            "en": "Do you want to convert your casino cash to sports cash?",
            "fra": "Voulez-vous convertir votre argent de casino en argent de sport ?",
            "spa": "¿Quiere convertir su efectivo de casino en efectivo deportivo?"
        },


    },
    USER: {
        "USER_INFO": {
            "kr": "회원정보",
            "jp": "会員情報",
            "en": "User Info",
            "fra": "Profil",
            "spa": "Perfil"
        },
        "PW_CHANGE_SUCCESS": {
            "kr": "비밀번호 변경성공",
            "jp": "パスワード変更成功",
            "en": "Password change success",
            "fra": "Changement de mot de passe réussi",
            "spa": "Cambio de contraseña exitoso"
        },
        "PHONE": {
            "kr": "휴대폰",
            "jp": "電話",
            "en": "phone",
            "fra": "téléphoner",
            "spa": "teléfono"
        },
        "LEVEL": {
            "kr": "레벨",
            "jp": "レベル",
            "en": "level",
            "fra": "niveau",
            "spa": "nivel"
        },
        "LOGIN_INFO": {
            "kr": "로그인정보",
            "jp": "ログイン情報",
            "en": "Login information",
            "fra": "Informations de connexion",
            "spa": "Información Entrar"
        },
        "LOGIN_TIME": {
            "kr": "로그인시간",
            "jp": "ログイン時間",
            "en": "login time",
            "fra": "heure de connexion",
            "spa": "tiempo de inicio de sesión"
        },
        "MY_REFERRAL_CODE": {
            "kr": "나의 추천코드",
            "jp": "私のおすすめコード",
            "en": "my referral code",
            "fra": "mon code de parrainage",
            "spa": "mi código de referencia"
        },
        "MY_REFERRAL": {
            "kr": "나의 추천인",
            "jp": "私のおすすめ",
            "en": "my recommender",
            "fra": "mon conseiller",
            "spa": "mi recomendador"
        },
        "MEMBERS_I_RECOMMEND": {
            "kr": "추천회원",
            "jp": "おすすめ会員",
            "en": "Recommended member",
            "fra": "Membre recommandé",
            "spa": "Miembro recomendado"
        },
        "USER_NAME": {
            "kr": "아이디",
            "jp": "ID",
            "en": "ID",
            "fra": "identifiant",
            "spa": "identificación"
        },
        "NICK_NAME": {
            "kr": "닉넴",
            "jp": "ニックネーム",
            "en": "nickname",
            "fra": "surnom",
            "spa": "apodo"
        },
        "PASSWORD": {
            "kr": "비밀번호",
            "jp": "パスワード",
            "en": "Password",
            "fra": "le mot de passe",
            "spa": "clave"
        },
        "RE_PASSWORD": {
            "kr": "비밀번호확인",
            "jp": "パスワード確認",
            "en": "Re-enter password",
            "fra": "vérifier le mot de passe",
            "spa": "Verificar contraseña"
        },
        "SIGNUP_CODE": {
            "kr": "가입코드",
            "jp": "サインアップコード",
            "en": "signup code",
            "fra": "code d'inscription",
            "spa": "código de registro"
        },
        "SIGN_UP": {
            "kr": "가입하기",
            "jp": "会員登録",
            "en": "Sign up",
            "fra": "S'inscrire",
            "spa": "inscribirse"
        },
        "USER_NAME_PLACEHOLDER": {
            "kr": "아이디 4-15자리 영문,숫자조합",
            "jp": "ID 4〜15桁の英語、数字の組み合わせ",
            "en": "ID 4-15 alphanumeric characters",
            "fra": "ID 4-15 caractères alphanumériques",
            "spa": "ID 4-15 caracteres alfanuméricos"
        },
        "NICK_NAME_PLACEHOLDER": {
            "kr": "닉네임 2-8자리 입력가능",
            "jp": "ニックネーム2-8桁入力可能",
            "en": "2-8 digits of nickname can be entered",
            "fra": "2 à 8 chiffres de surnom peuvent être entrés",
            "spa": "Se pueden ingresar de 2 a 8 dígitos de apodo"
        },
        "PASSWORD_PLACEHOLDER": {
            "kr": "비밀번호  4-20자리 영문,숫자",
            "jp": "パスワード4〜20桁の英語、数字",
            "en": "Password 4-20 alphanumeric characters",
            "fra": "Mot de passe 4-20 caractères alphanumériques",
            "spa": "Contraseña 4-20 caracteres alfanuméricos"
        },
        "RE_PASSWORD_PLACEHOLDER": {
            "kr": "비밀번호확인",
            "jp": "パスワード確認",
            "en": "Re-enter password",
            "fra": "vérifier le mot de passe",
            "spa": "Verificar contraseña"
        },
        "PHONE_PLACEHOLDER": {
            "kr": "'-' 없이 숫자만 입력",
            "jp": "'-'なしで数字のみを入力",
            "en": "Enter only numbers without '-'",
            "fra": "Entrez uniquement des chiffres sans '-'",
            "spa": "Ingrese solo números sin '-'"
        },
        "SIGNUP_CODE_PLACEHOLDER": {
            "kr": "가입코드 있을경우 입력",
            "jp": "サインアップコードがある場合",
            "en": "If you have a subscription code, enter it",
            "fra": "Si vous avez un code d'abonnement, saisissez-le",
            "spa": "Si tienes un código de suscripción, ingrésalo"
        },
        "USER_COIN": {
            "kr": "보유코인",
            "jp": "保有コイン",
            "en": "You coins",
            "fra": "pièces de monnaie",
            "spa": "sosteniendo monedas"
        },
        "COIN_NAME": {
            "kr": "코인",
            "jp": "COIN",
            "en": "COIN",
            "fra": "COIN",
            "spa": "COIN"
        }
        ,
        "RE_PASSWORD_ERROR_MSG": {
            "kr": "입력하신 비밀번호가 동일하지 않습니다",
            "jp": "入力したパスワードが同じではありません",
            "en": "The password you entered is not the same",
            "fra": "Le mot de passe que vous avez entré n'est pas le même",
            "spa": "La contraseña que ingresaste no es la misma"
        },
        "WELCOME_NEW_USER_MSG": {
            "kr": "회원가입을 축하드립니다.관리자 확인후 이용가능합니다",
            "jp": "会員登録おめでとうございます。管理者確認後に利用可能です",
            "en": "Congratulations on joining as a member. Available after administrator confirmation",
            "fra": "Félicitations pour votre adhésion en tant que membre. Vous pouvez l'utiliser après avoir vérifié l'administrateur",
            "spa": "Felicidades por unirte como miembro. Puedes usarlo después de consultar al administrador."
        },
        "USER_NAME_ERROR_MSG": {
            "kr": "아이디는 4-15자리 영문,숫자조합입니다",
            "jp": "IDは4〜15桁の英語、数字の組み合わせです",
            "en": "ID is a combination of 4-15 alphanumeric characters.",
            "fra": "ID est une combinaison de 4 à 15 caractères alphanumériques.",
            "spa": "ID es una combinación de 4-15 caracteres alfanuméricos."
        },
        "NICK_NAME_ERROR_MSG": {
            "kr": "닉네임은 2-6자리 사용 가능합니다",
            "jp": "ニックネームは2〜6桁利用可能です",
            "en": "Nickname can be 2-6 digits",
            "fra": "Le surnom peut être composé de 2 à 6 chiffres",
            "spa": "El apodo puede tener de 2 a 6 dígitos"
        },
        "PASSWORD_ERROR_MSG": {
            "kr": "비밀번호는 4-20자리 입니다",
            "jp": "パスワードは4〜20桁です",
            "en": "The password is 4-20 digits.",
            "fra": "Le mot de passe est composé de 4 à 20 chiffres.",
            "spa": "La contraseña es de 4-20 dígitos."
        },
        "PHONE_ERROR_MSG": {
            "kr": "폰번호 서식 오류입니다",
            "jp": "電話番号の書式設定エラーです。",
            "en": "Phone number format error.",
            "fra": "Erreur de format de numéro de téléphone",
            "spa": "Error de formato de número de teléfono"
        },
        "REFERRE_ERROR_MSG": {
            "kr": "추천인 코드를 정확하게 입력하세요",
            "jp": "おすすめのコードを正確に入力してください",
            "en": "Please enter your referral code correctly.",
            "fra": "Veuillez saisir correctement votre code de parrainage",
            "spa": "Por favor ingrese su código de referencia correctamente"
        },


    },

    GAME: {
        "WIN": {
            "kr": "우승",
            "jp": "勝つ",
            "en": "Win",
            "fra": "gagner",
            "spa": "ganar"
        },
        "HOME_WIN": {
            "kr": "홈승",
            "jp": "ホーム勝利",
            "en": "home win",
            "fra": "victoire à domicile",
            "spa": "victoire à domicile"
        },
        "AWAY_WIN": {
            "kr": "원정승",
            "jp": "あちらへ勝利",
            "en": "away win",
            "fra": "une façon win",
            "spa": "lejos win"
        },
        "PREMIERSHIP": {
            "kr": "프리미어",
            "jp": "プレミアシップ",
            "en": "Premiership",
            "fra": "Premiership",
            "spa": "Premiership"
        },
        "SUPERLEAGUE": {
            "kr": "슈퍼리그",
            "jp": "スーパーリーグ",
            "en": "Superleague",
            "fra": "Superleague",
            "spa": "Superleague"
        },
        "EURO_CUP": {
            "kr": "유로컵",
            "jp": "ユーロカップ",
            "en": "Euro Cup",
            "fra": "Euro Cup",
            "spa": "Euro Cup"
        },
        "WORLD_CUP": {
            "kr": "월드컵",
            "jp": "ワールドカップ",
            "en": "World Cup",
            "fra": "World Cup",
            "spa": "World Cup"
        },
        "GOLDENHILL": {
            "kr": "개경주(골든힐)",
            "jp": "ゴールデンヒルパーク",
            "en": "Goldenhill Park",
            "fra": "Goldenhill Park",
            "spa": "Goldenhill Park"
        },
        "HILLSIDE": {
            "kr": "개경주(힐사이드)",
            "jp": "ヒルサイドパーク",
            "en": "Hillside Park",
            "fra": "Hillside Park",
            "spa": "Hillside Park"
        },
        "MINUTES": {
            "kr": "분",
            "jp": "分",
            "en": "minutes",
            "fra": "minute",
            "spa": "minuto"
        },
        "WINNING_NUMBER": {
            "kr": "우승번호",
            "jp": "優勝番号",
            "en": "Winning number",
            "fra": "numéro gagnant",
            "spa": "numero ganador"
        },
        "NO_1": {
            "kr": "1번",
            "jp": "No.1",
            "en": "No.1",
            "fra": "No.1",
            "spa": "No.1"
        },
        "NO_2": {
            "kr": "2번",
            "jp": "No.2",
            "en": "No.2",
            "fra": "No.2",
            "spa": "No.2"
        },
        "NO_3": {
            "kr": "3번",
            "jp": "No.3",
            "en": "No.3",
            "fra": "No.3",
            "spa": "No.3"
        },
        "NO_4": {
            "kr": "4번",
            "jp": "No.4",
            "en": "No.4",
            "fra": "No.4",
            "spa": "No.4"
        },
        "NO_5": {
            "kr": "5번",
            "jp": "No.5",
            "en": "No.5",
            "fra": "No.5",
            "spa": "No.5"
        },
        "NO_6": {
            "kr": "6번",
            "jp": "No.6",
            "en": "No.6",
            "fra": "No.6",
            "spa": "No.6"
        },
        "NO_GAMES": {
            "kr": "현재 진행중인 경기가 없습니다",
            "jp": "現在進行中の試合はありません",
            "en": "There are currently no matches in progress",
            "fra": "Il n'y a actuellement aucun match en cours",
            "spa": "Actualmente no hay partidos en curso"
        },
        "SPORT_RESULT": {
            "kr": "스포츠 결과",
            "jp": "スポーツ結果",
            "en": "Sports Result",
            "fra": "résultats sportifs",
            "spa": "resultados deportivos"
        },
        "MINI_RESULT": {
            "kr": "미니게임 결과",
            "jp": "ミニゲーム結果",
            "en": "MiniGame Result",
            "fra": "Résultats du mini-jeu",
            "spa": "Resultados del minijuego"
        },
        "MATCH_RESETING": {
            "kr": "경기 초기화 중입니다",
            "jp": "試合の初期化中です",
            "en": "Match is being reset",
            "fra": "Le match est en cours de réinitialisation",
            "spa": "El partido se está reiniciando"
        },


    },
    RULE: {
        "TERMS_OF_SERVICE": {
            "kr": "이용약관",
            "jp": "利用規約",
            "en": "Terms of service",
            "fra": "Conditions d'utilisation",
            "spa": "Términos de servicio"
        }
    },
    //입금관련
    COIN: {
        "COIN_CONVERSION_MSG_1": {
            "kr": "전환신청 하시겠습니까? ",
            "jp": "切り替えを申し込みますか？",
            "en": "Do you want to apply for conversion?",
            "fra": "Vous souhaitez faire une demande de conversion ?",
            "spa": "¿Quieres solicitar la conversión?"
        },
        "COIN_IN_MSG_1": {
            "kr": "최소 전환금액은 10$이상 가능합니다.",
            "jp": "最小換算金額は10ドル以上可能です。",
            "en": "The minimum conversion amount is 10$ or more.",
            "fra": "Le montant minimum de conversion est de 10 $ ou plus.",
            "spa": "El monto mínimo de conversión es de 10 $ o más."
        },
        "COIN_RATE": {
            "kr": "가격",
            "jp": "Coin価格",
            "en": "Coin price",
            "fra": "Coin le prix",
            "spa": "Coin precio"
        },
        "COIN_RATE2": {
            "kr": "환율",
            "jp": "Exchange Rate",
            "en": "Exchange Rate",
            "fra": "Exchange Rate",
            "spa": "Exchange Rate"
        },
        "CAN_CHANGE_COIN": {
            "kr": "전환가능금액",
            "jp": "転換金額",
            "en": "conversion amount",
            "fra": "montant de conversion",
            "spa": "cantidad de conversión"
        },
        "COIN_OUT_WALLET_ADDRESS": {
            "kr": "출금지갑주소",
            "jp": "出金電子財布アドレス",
            "en": "Withdrawal e-wallet address",
            "fra": "Adresse du portefeuille de retrait",
            "spa": "Dirección de la billetera de retiro"
        },
        "COIN_WALLET_ADDRESS": {
            "kr": "지갑주소",
            "jp": "財布アドレス",
            "en": "wallet address",
            "fra": "adresse de portefeuille",
            "spa": "dirección de la billetera"
        },

        "COIN_CONVERSION": {
            "kr": "코인전환신청",
            "jp": "コイン切替申請",
            "en": "Coin Conversion",
            "fra": "Conversion de pièces",
            "spa": "Conversión de monedas"
        },
        "ACCEPT_BONUS": {
            "kr": "보너스 동의를 선택하세요",
            "jp": "ボーナス同意を選択してください",
            "en": "Choose to accept the bonus",
            "fra": "Choisissez d'accepter le bonus",
            "spa": "Elija aceptar el bono"
        },
        "COIN_OUT_MSG_1": {
            "kr": "코인전환",
            "jp": "Change Coin",
            "en": "Change Coin",
            "fra": "Change Coin",
            "spa": "Change Coin"
        },
        "COIN_OUT_MSG_2": {
            "kr": "코인전송",
            "jp": "Send Coin",
            "en": "Send Coin",
            "fra": "Send Coin",
            "spa": "Send Coin"
        },
        "CONVERTIBLE_COINS": {
            "kr": "전환가능 코인수",
            "jp": "切り替え可能コイン数",
            "en": "Number of convertible coins",
            "fra": "Nombre de pièces convertibles",
            "spa": "Número de monedas convertibles"
        },
        "CONVERTIBLE_COINS_REQUEST": {
            "kr": "코인전환신청",
            "jp": "コイン転換",
            "en": "Convertible Coins",
            "fra": "Conversion de pièces",
            "spa": "Conversión de monedas"
        },   
        "CONVERTIBLE_COINS_REQUEST2": {
            "kr": "코인전송",
            "jp": "SEND COIN",
            "en": "SEND COIN",
            "fra": "SEND COIN",
            "spa": "SEND COIN"
        },
        "COPY_WALLET": {
            "kr": "지갑 주소가 복사되였습니다",
            "jp": "COPY SUCCESS",
            "en": "COPY SUCCESS",
            "fra": "COPY SUCCESS",
            "spa": "COPY SUCCESS"
        }

    },
    //포인트
    POINT: {
        "POINT_HISTORY": {
            "kr": "포인트 내역",
            "jp": "ポイント履歴",
            "en": "Point history",
            "fra": "Historique des points",
            "spa": "Historial de puntos"
        }
    },
    //포인트
    QUESTION: {
        "QUESTION_CONTENT_MSG_1": {
            "kr": "문의글 작성이 완료되였습니다",
            "jp": "お問い合わせの作成が完了しました",
            "en": "The inquiry has been completed",
            "fra": "L'enquête est terminée",
            "spa": "La consulta ha sido completada"
        },
    },
    //쪽지
    MESSAGE: {
        "UNCONFIRMED_MESSAGE": {
            "kr": "미확인 쪽지",
            "jp": "未確認のメモ",
            "en": "Unconfirmed message",
            "fra": "note non confirmée",
            "spa": "nota no confirmada"
        },
    },

    //베팅관련
    BET: {

        "ALL_IN": {
            "kr": "올인",
            "jp": "オールイン",
            "en": "All in",
            "fra": "tout en",
            "spa": "todo dentro"
        },
        "BOTH_TEAM_TO_SCORE": {
            "kr": "양팀 모두 득점",
            "jp": "両チームとも得点",
            "en": "both teams to score",
            "fra": "Les deux equipes marquent",
            "spa": "ambos equipos a marcar"
        },
        "SCORE_ODD_EVEN": {
            "kr": "스코어 홀/짝",
            "jp": "スコアホール/ペア",
            "en": "Score Odd/Even",
            "fra": "score pair/impair",
            "spa": "puntuación par/impar"
        },
        "FINAL_SCORE": {
            "kr": "최종스코어",
            "jp": "最終スコア",
            "en": "Final score",
            "fra": "score final",
            "spa": "puntuación final"
        },
        "TOTAL_HITS": {
            "kr": "안타 총개수",
            "jp": "ヒット総数",
            "en": "total number of hits",
            "fra": "nombre total de visites",
            "spa": "número total de visitas"
        },
        "ALTERNATIVE_OVERUNDER": {
            "kr": "Alternative 오버언더",
            "jp": "Alternative OverUnder",
            "en": "Alternative OverUnder",
            "fra": "Alternative OverUnder",
            "spa": "Alternative OverUnder"
        },
        "HITS_WIN_LOSE": {
            "kr": "안타 승무패",
            "jp": "Hit win or loss",
            "en": "Hit win or loss",
            "fra": "Hit win or loss",
            "spa": "Hit win or loss"
        },
        "HITS_OVERUNDER": {
            "kr": "안타 오버언더",
            "jp": "Hit OverUnder",
            "en": "Hit OverUnder",
            "fra": "Hit OverUnder",
            "spa": "Hit OverUnder"
        },
        "BOTH_TEAMS_SCORE": {
            "kr": "양 팀 점수",
            "jp": "both team score",
            "en": "both team score",
            "fra": "both team score",
            "spa": "both team score"
        },
        "ALT_PUCK_HANDICAP": {
            "kr": "Alt Puck 핸디캡",
            "jp": "Alt Puck handicap",
            "en": "Alt Puck handicap",
            "fra": "Alt Puck handicap",
            "spa": "Alt Puck handicap"
        },
        "ALT_PUCK_OVERUNDER": {
            "kr": "Alt Puck 오버언더",
            "jp": "Alt Puck Overunder",
            "en": "Alt Puck Overunder",
            "fra": "Alt Puck Overunder",
            "spa": "Alt Puck Overunder"
        },


        "FULL_TIME": {
            "kr": "풀타임",
            "jp": "Full time",
            "en": "Full time",
            "fra": "Full time",
            "spa": "Full time"
        },
        "SELECT_BET_GAME": {
            "kr": "베팅하실 경기를 선택하세요",
            "jp": "ベットるゲームを選択してください",
            "en": "Choose the match you want to bet on",
            "fra": "Choisissez le match sur lequel vous voulez parier",
            "spa": "Elige el partido en el que quieres apostar"
        },
        "MAX_BET_CASH": {
            "kr": "최대베팅",
            "jp": "最大ベット",
            "en": "Max bet",
            "fra": "Max bet",
            "spa": "Max bet"
        },
        "MAX_WIN_CASH": {
            "kr": "최대적중",
            "jp": "最大ヒット",
            "en": "Max win",
            "fra": "Max win",
            "spa": "Max win"
        },
        "GAME_MONEY": {
            "kr": "보유머니",
            "jp": "保有マネー",
            "en": "cash",
            "fra": "cash",
            "spa": "cash"
        },
        "BONUS_RATE": {
            "kr": "보너스 배당",
            "jp": "ボーナス配当",
            "en": "bonus rate",
            "fra": "bonus rate",
            "spa": "bonus rate"
        },
        "BONUS_AMOUNT": {
            "kr": "보너스 금액",
            "jp": "ボーナス額",
            "en": "bonus amount",
            "fra": "bonus amount",
            "spa": "bonus amount"
        },
        "RATE": {
            "kr": "배당",
            "jp": "rate",
            "en": "rate",
            "fra": "rate",
            "spa": "rate"
        },
        "INIT": {
            "kr": "초기화",
            "jp": "初期化",
            "en": "Init",
            "fra": "Init",
            "spa": "Init"
        },
        "EXPECTED_WIN_CASH": {
            "kr": "예상당첨금액",
            "jp": "予想当選金額",
            "en": "expected winning amount",
            "fra": "Montant du gain attendu",
            "spa": "Cantidad ganadora esperada"
        },
        "BET_CASH": {
            "kr": "베팅금액",
            "jp": "ベット金額",
            "en": "Bet cash",
            "fra": "Bet cash",
            "spa": "Bet cash"
        },
        "DO_BET": {
            "kr": "베팅하기",
            "jp": "Beting",
            "en": "Beting",
            "fra": "Beting",
            "spa": "Beting"
        },
        "MIN_BET_CASH": {
            "kr": "최소베팅금액",
            "jp": "最小ベット金額",
            "en": "Minimum bet amount",
            "fra": "Montant de pari minimum",
            "spa": "Cantidad mínima de apuesta"
        },
        "ONE_BET_MAX_CASH": {
            "kr": "단폴최대베팅금액",
            "jp": "単極最大ベット額",
            "en": "Danpole maximum bet amount",
            "fra": "Montant de la mise maximale Danpole",
            "spa": "Cantidad máxima de apuesta de Danpole"
        },
        "BET_MAX_CASH": {
            "kr": "최대베팅금액",
            "jp": "最大ベット金額",
            "en": "Maximum bet amount",
            "fra": "Montant maximum de la mise",
            "spa": "Monto máximo de la apuesta"
        },
        "MAX_WIN_AMOUNT": {
            "kr": "최대당첨금액",
            "jp": "最大当選金額",
            "en": "Maximum winning",
            "fra": "gain maximum",
            "spa": "victoria máxima"
        },
        "RESET": {
            "kr": "정정하기",
            "jp": "リセット",
            "en": "Reset",
            "fra": "réinitialiser",
            "spa": "Reiniciar"
        },
        "LOSE": {
            "kr": "낙첨",
            "jp": "落下",
            "en": "Lose",
            "fra": "perdant",
            "spa": "perdedor"
        },
        "WINNING": {
            "kr": "당첨",
            "jp": "当選",
            "en": "Winning",
            "fra": "gagnant",
            "spa": "victorioso"
        },
        "WAITING": {
            "kr": "대기",
            "jp": "待つ",
            "en": "Wating",
            "fra": "Attendre",
            "spa": "Esperando"
        },
        "WIN_TIE_LOSE": {
            "kr": "승무패",
            "jp": "1X2",
            "en": "1X2",
            "fra": "1X2",
            "spa": "1X2"
        },
        "HANDICAP": {
            "kr": "핸디캡",
            "jp": "Handicap",
            "en": "Handicap",
            "fra": "Handicap",
            "spa": "Handicap"
        },
        "OVER_UNDER": {
            "kr": "오버언더",
            "jp": "Overunder",
            "en": "Overunder",
            "fra": "Overunder",
            "spa": "Overunder"
        },
        "OTHER": {
            "kr": "기타",
            "jp": "その他",
            "en": "Other",
            "fra": "etc",
            "spa": "etc"
        },

        "BET_TYPE": {
            "kr": "베팅타입",
            "jp": "ベットタイプ",
            "en": "bet type",
            "fra": "type de pari",
            "spa": "tipo de apuesta"
        },
        "DIVISION": {
            "kr": "구분",
            "jp": "分類",
            "en": "division",
            "fra": "division",
            "spa": "división"
        },
        "OPTIONAL": {
            "kr": "선택항목",
            "jp": "選択項目",
            "en": "optional",
            "fra": "optionnel",
            "spa": "Opcional"
        },
        "BETING_RESULT": {
            "kr": "베팅결과",
            "jp": "ベット結果",
            "en": "betting result",
            "fra": "résultat du pari",
            "spa": "resultado de apuestas"
        },
        "BET_SUCCESS": {
            "kr": "베팅성공",
            "jp": "ベット成功",
            "en": "Bet success",
            "fra": "pari réussi",
            "spa": "apuesta exitosa"
        },


        "CANCELL_BET_MSG_1": {
            "kr": "베팅내역을 취소하시겠습니까?",
            "jp": "ベットをキャンセルしますか？",
            "en": "Are you sure you want to cancel your bet history?",
            "fra": "Êtes-vous sûr de vouloir annuler votre pari ?",
            "spa": "¿Estás seguro de que quieres cancelar tu apuesta?"
        },
        "DELETE_BET_MSG_1": {
            "kr": "베팅내역을 삭제하시겠습니까?",
            "jp": "ベット履歴を削除してもよろしいですか？",
            "en": "Are you sure you want to delete your bet history?",
            "fra": "Voulez-vous vraiment supprimer vos paris ?",
            "spa": "¿Está seguro de que desea eliminar sus apuestas?"
        },

        "BET_RUL_MSG_1": {
            "kr": "크로스 베팅이 허용되지 않습니다",
            "jp": "クロスベットは許可されていません",
            "en": "Cross betting is not allowed",
            "fra": "Les paris croisés ne sont pas autorisés",
            "spa": "No se permiten apuestas cruzadas"
        },
        "BET_RUL_MSG_2": {
            "kr": "동일경기 크로스 베팅이 허용되지 않습니다",
            "jp": "同じ試合のクロスベットは許可されていません",
            "en": "Same match cross bets are not allowed",
            "fra": "Les paris croisés sur le même match ne sont pas autorisés",
            "spa": "No se permiten apuestas cruzadas al mismo partido"
        },
        "BET_RUL_MSG_3": {
            "kr": "동일경기 승무패 홈승, 핸디캡 홈승은 조합할수 없습니다",
            "jp": "同一試合1X2ホーム勝、ハンディキャップホーム勝は組み合わせることができません",
            "en": "Same match win lose, home win and handicap home win cannot be combined.",
            "fra": "La victoire à domicile 1X2 et la victoire à domicile avec handicap dans le même match ne peuvent pas être combinées",
            "spa": "La victoria en casa 1X2 y la victoria en casa con hándicap en el mismo partido no se pueden combinar"
        },
        "BET_RUL_MSG_4": {
            "kr": "동일경기 승무패 홈승, 핸디캡 원정승은 조합할수 없습니다",
            "jp": "同じ試合1x2ホーム勝、ハンディキャップ遠征勝は組み合わせることができません",
            "en": "Same match win lose, home win and handicap away win cannot be combined.",
            "fra": "Les victoires 1x2 à domicile et les victoires avec handicap à l'extérieur dans le même match ne peuvent pas être combinées",
            "spa": "1x2 victorias en casa y victorias fuera de casa con handicap en el mismo partido no se pueden combinar"
        },
        "BET_RUL_MSG_5": {
            "kr": "동일경기 승무패 무, 핸디캡 홈승은 조합할수 없습니다",
            "jp": "同じ試合の 1x2 、ハンディキャップホーム勝利は組み合わせることができません",
            "en": "Same match 1x2 draw and handicap home win cannot be combined.",
            "fra": "Le match nul 1x2 et la victoire à domicile avec handicap dans le même match ne peuvent pas être combinés",
            "spa": "Empate 1x2 y victoria local con hándicap en el mismo partido no se pueden combinar"
        },
        "BET_RUL_MSG_6": {
            "kr": "동일경기 승무패 무, 핸디캡 원정승은 조합할수 없습니다",
            "jp": "同一試合 1x2 無、ハンディキャップ遠征勝は組合できません",
            "en": "Same match win lose, tie and handicap away win cannot be combined.",
            "fra": "Le même match nul 1x2 et la victoire avec handicap à l'extérieur ne peuvent pas être combinés",
            "spa": "Mismo partido 1x2 empate y victoria fuera de casa con hándicap no se pueden combinar"
        },
        "BET_RUL_MSG_7": {
            "kr": "동일경기 승무패 원정승, 핸디캡 홈승은 조합할수 없습니다",
            "jp": "同一試合1x2遠征勝、ハンディキャップホーム勝は組み合わせることができません",
            "en": "Same match win lose, away wein and handicap home win cannot be combined.",
            "fra": "Les victoires 1x2 à l'extérieur et les victoires à domicile avec handicap dans le même match ne peuvent pas être combinées",
            "spa": "1x2 victorias fuera de casa y victorias en casa con handicap en el mismo partido no se pueden combinar"
        },
        "BET_RUL_MSG_8": {
            "kr": "동일경기 승무패 원정승, 핸디캡 원정승은 조합할수 없습니다",
            "jp": "同じ試合1x2遠征勝、ハンディキャップ遠征勝は組み合わせることができません",
            "en": "Same match win lose, away win and handicap away win cannot be combined.",
            "fra": "Les victoires à l'extérieur 1x2 et les victoires à l'extérieur avec handicap dans le même match ne peuvent pas être combinées",
            "spa": "Las victorias fuera de casa 1x2 y las victorias fuera de casa con hándicap en el mismo partido no se pueden combinar"
        },
        "BET_RUL_MSG_9": {
            "kr": "동일경기 승무패 홈승, 오버는 조합할수 없습니다",
            "jp": "同じ試合1x2ホーム勝、オーバーは組み合わせることができません",
            "en": "Same match win lose, home win and over cannot be combined.",
            "fra": "Victoire 1x2 à domicile dans le même match, Over ne peut pas être combiné",
            "spa": "1x2 victoria en casa en el mismo partido, Over no se puede combinar"
        },
        "BET_RUL_MSG_10": {
            "kr": "동일경기 승무패 홈승, 언더는 조합할수 없습니다",
            "jp": "同じ試合1x2ホーム勝、アンダーは組み合わせることができません",
            "en": "Same match win lose, home win and under cannot be combined",
            "fra": "Victoire 1x2 à domicile dans le même match, les moins ne peuvent pas être combinés",
            "spa": "1x2 victoria en casa en el mismo partido, menos no acumulable"
        },
        "BET_RUL_MSG_11": {
            "kr": "동일경기 승무패 무, 오버는 조합할수 없습니다",
            "jp": "同一試合 1x2 無、オーバーは組み合わせることができません",
            "en": "Same match win lose, draw and over cannot be combined.",
            "fra": "Le même match nul 1x2 et plus ne peut pas être combiné",
            "spa": "Mismo partido empate 1x2 y más no se puede combinar"
        },
        "BET_RUL_MSG_12": {
            "kr": "동일경기 승무패 무와  언더는 조합할수 없습니다",
            "jp": "同じ試合1x2大根とアンダーを組み合わせることはできません",
            "en": "Same match win lose, draw and under cannot be combined.",
            "fra": "1x2 Draw et Under ne peuvent pas être combinés dans le même match",
            "spa": "Empate 1x2 y Menos no se pueden combinar en el mismo partido"
        },
        "BET_RUL_MSG_13": {
            "kr": "동일경기 승무패 원정승, 오버는 조합할수 없습니다",
            "jp": "同じ試合1x2遠征勝、オーバーは組み合わせることができません",
            "en": "Same match win lose, away win and over cannot be combined.",
            "fra": "Victoire 1x2 à l'extérieur dans le même match, Over ne peut pas être combiné",
            "spa": "1x2 victoria fuera de casa en el mismo partido, Over no se puede combinar"
        },
        "BET_RUL_MSG_14": {
            "kr": "동일경기 승무패 원정승, 언더는 조합할수 없습니다",
            "jp": "同じ試合1x2遠征勝、アンダーは組み合わせることができません",
            "en": "Same match win lose, away win and under cannot be combined.",
            "fra": "Même match 1x2 victoire à l'extérieur, Under ne peut pas être combiné",
            "spa": "Mismo partido 1x2 victoria visitante, Under no se puede combinar"
        },
        "BET_RUL_MSG_15": {
            "kr": "동일경기 핸디캡 홈승, 오버는 조합할수 없습니다",
            "jp": "同じ試合ハンディキャップホーム勝、オーバーは組み合わせることができません",
            "en": "Same match handicap , home win and over cannot be combined.",
            "fra": "Les victoires à domicile avec handicap et plus dans le même match ne peuvent pas être combinées",
            "spa": "No se pueden combinar victorias en casa con hándicap y más en el mismo partido."
        },
        "BET_RUL_MSG_16": {
            "kr": "동일경기 핸디캡 홈승, 언더는 조합할수 없습니다",
            "jp": "同じ試合ハンディキャップホーム勝、アンダーは組み合わせることができません",
            "en": "Same match handicap , home win and under cannot be combined.",
            "fra": "Vous ne pouvez pas combiner la victoire à domicile avec handicap et moins dans le même match.",
            "spa": "No se puede combinar victoria en casa con hándicap y menos en el mismo partido."
        },
        "BET_RUL_MSG_17": {
            "kr": "동일경기 핸디캡 원정승, 오버는 조합할수 없습니다",
            "jp": "同じ試合ハンディキャップ遠征勝、オーバーは組み合わせることができません",
            "en": "Same match handicap , away win and over cannot be combined.",
            "fra": "Les victoires à l'extérieur avec handicap et les overs dans le même match ne peuvent pas être combinés",
            "spa": "No se pueden combinar victorias y overs de visitante con hándicap en el mismo partido."
        },
        "BET_RUL_MSG_18": {
            "kr": "동일경기 핸디캡 원정승, 언더는 조합할수 없습니다.",
            "jp": "同じ試合ハンディキャップ遠征勝、アンダーは組み合わせることができません。",
            "en": "Same match handicap , away win and under cannot be combined.",
            "fra": "Le même handicap de match à l'extérieur et moins ne peuvent pas être combinés.",
            "spa": "No se puede combinar el mismo partido de hándicap fuera de casa ganado y menor."
        },
        "DO_BET_MSG_1": {
            "kr": "## $를 베팅하시겠습니까?",
            "jp": "## ドルをベットますか？",
            "en": "Would you like to bet ## $?",
            "fra": "Souhaitez-vous parier ## $?",
            "spa": "¿Te gustaría apostar ## $?"
        },
        "DO_BET_MSG_2": {
            "kr": "단폴더 최대 베팅금액 ## $을 초과하였습니다",
            "jp": "シングルフォルダの最大ベット額が##ドルを超えました",
            "en": "Single folder maximum bet amount exceeded ## $",
            "fra": "Le montant maximum de la mise sur un dossier a dépassé ## $",
            "spa": "El monto máximo de la apuesta de una sola carpeta excedió los ## $"
        },
        "DO_BET_MSG_3": {
            "kr": "두폴더 최대 베팅금액 ## $ 초과하였습니다",
            "jp": "ダブルフォルダの最大ベット金が##ドルを超えました",
            "en": "The maximum bet amount of two folders exceeded ## $",
            "fra": "Dépassé le montant maximum de pari de deux dossiers de ## $",
            "spa": "Superó la cantidad máxima de apuesta de dos carpetas en ## $"
        },
        "DO_BET_MSG_4": {
            "kr": "최대 배당 ## 배를 초과하였습니다",
            "jp": "最大当選配当##倍を超えました",
            "en": "Exceeded the maximum winnings by ## times",
            "fra": "Dépassé le maximum de gains de ## fois",
            "spa": "Superó las ganancias máximas en ## veces"
        },
        "DO_BET_MSG_5": {
            "kr": "최소 베팅금액은 ## $입니다.",
            "jp": "最低ベット金額は##$です。",
            "en": "The minimum bet amount is ## $.",
            "fra": "Le montant minimum de la mise est de ## $.",
            "spa": "La apuesta mínima es de ## $."
        },
        "DO_BET_MSG_6": {
            "kr": "최대 베팅금액 ## $ 초과하였습니다.",
            "jp": "最大ベット金 ## ​​$ 超過しました。",
            "en": "The maximum bet amount ## $ has been exceeded.",
            "fra": "Le montant maximum de la mise ## $ a été dépassé.",
            "spa": "Se ha excedido la cantidad máxima de apuesta ## $."
        },
        "DO_BET_MSG_7": {
            "kr": "최대 당첨금액  ## $ 초과하였습니다.",
            "jp": "最大当選金額##$超過しました。",
            "en": "Maximum bet amount ## $ exceeded.",
            "fra": "Le montant maximum de gain de ## $ a été dépassé.",
            "spa": "Se ha excedido la cantidad máxima ganadora de ## $."
        },
        "DO_BET_MSG_8": {
            "kr": "## $ 베팅하시겠습니까?",
            "jp": "##$ベットしますか？",
            "en": "Would you like to bet ## $?",
            "fra": "Voulez-vous parier ## $?",
            "spa": "¿Quieres apostar ## $?"
        },
        "DO_BET_MSG_9": {
            "kr": "축구 동일경기 크로스 최소 ##폴더 부터 베팅가능합니다.",
            "jp": "サッカーの同じ試合クロス最低##フォルダからベットことができます。",
            "en": "Soccer Same Match Cross Betting is possible from the minimum ## folder.",
            "fra": "Vous pouvez parier à partir d'un minimum de ## dossiers dans le même match de football.",
            "spa": "Se puede apostar a partir de un mínimo de ## carpetas en un mismo partido de fútbol."
        },


    }
}
