<template>
  <!--오른쪽 메뉴-->

  <div class="right_section" :class="{'right_section_show':isShowRightBar}">
    <div class="right_cont">
      <div class="right_items">
        <div class="btn-close">
          <button @click="closeRightBar()">
            <i class="fa fa-close"></i>
          </button>
        </div>
        <slot name="userinfo"></slot>
        <slot name="btns"></slot>
        <slot name="betcart"></slot>
        <slot name="banner"></slot>
      </div>

    </div>
  </div>
</template>

<script>
  import UserInfoComp from "./UserInfoComp";
  import SportsBetCartComp from "./sports/SportsBetCartComp";
  export default {
    name: "RightBarComp",
    components: {SportsBetCartComp, UserInfoComp},
    data() {
      return {
        isShowSubordinate: false,
        isShowRightBar: false,
        locked:false
      }
    },
    methods:{
      /*mobile 페이지 배팅카드 보여줄시 닫기*/
      closeRightBar() {
        //RightBarSportsComp.vue에서 모니터
        this.$bus.$emit('rightBarHandle', false);
      }
    },
    created() {
      //모바일 하단메뉴 베팅카드 메뉴 클릭시 오른쪽메뉴 클릭감시
      // //MobileTopMenuComp.vue에서 $emit
      this.$bus.$on('rightBarHandle', (isShow) => {
        this.isShowRightBar = isShow
      })
      //
      this.$bus.$on('cartLockAble', (lockable) => {
        this.locked = lockable
      })
    }
  }
</script>

<style scoped>
  .btn_close_right_bar{
    display: none;
    margin: 5px auto;
  }
</style>