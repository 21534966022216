import http from "./axios/http";

/**
 * toto cash change 2 bustabit cash
 */
export function totoCash2BustabitCash() {
  return http.get('/bustabit/toto_cash_to_bustabit_cash?t=' + new Date().getMilliseconds())
}

/**
 * bustabit cash change 2 toto cash
 */
export function bustabitCash2totoCash() {
  return http.get('/bustabit/bustabit_cash_to_toto_cash?t=' + new Date().getMilliseconds())
}

