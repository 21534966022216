<template>
    <div class="header" style="background-color: #e5e4e4" >
        <div class="mobile-menu-left-icon" @click="showMobileTopMenu">
            <img src="../assets/images/icon/common/menu1.svg" alt="">
        </div>
        <div class="top_1" >
            <div class="top_logo">
                <router-link tag="a" :to="{path: '/main', query: {t: new Date().getTime()}}">
                    <img height="90"  src="../assets/images/logo/betstoa/logo.png">
                </router-link>
            </div>
            <div class="top_buttons">
                <div  style="width: 60%;text-align: right;padding-right: 20px;">
                    <select @change="changeCountry($event)" v-model="$store.state.country">
                        <option :value="i18nConst.KR">한국</option>
                        <option :value="i18nConst.EN" >English</option>
                        <option :value="i18nConst.JP">日本語</option>
                        <option :value="i18nConst.FRA" >Français</option>
                        <option :value="i18nConst.SPA" >Español</option>
                    </select>
                    <!--
                    <button @click="setCountry(i18nConst.KR)" style="color: #1e1e22">한국</button>,
                    <button @click="setCountry(i18nConst.EN)" style="color: #1e1e22">English</button>,
                    <button @click="setCountry(i18nConst.JP)" style="color: #1e1e22">日本語</button>,
                    <button @click="setCountry(i18nConst.FRA)" style="color: #1e1e22">Français</button>,
                    <button @click="setCountry(i18nConst.SPA)" style="color: #1e1e22">Español</button>
                    -->
                </div>

                <div class="buttons" v-if="!$store.state.isLogin">
                    <button class="mb-button-1 mb-button--medium mb-button--blue login-button"
                            @click="showLoginPanel()">
                        <span>{{i18nConst.COMMON['LOGIN'][$store.state.country]}}</span>
                    </button>
                    <button class="mb-button-1 mb-button--medium mb-button--orange  register-button"
                            @click="showRegisterPanel()"><span>{{i18nConst.COMMON['REGISTE'][$store.state.country]}}</span>
                    </button>
                </div>
                <div class="buttons" v-if="$store.state.isLogin">
                    <button class="mb-button-1 mb-button--medium mb-button--orange  register-button" @click="logout">
                        <span>{{i18nConst.COMMON['LOGOUT'][$store.state.country]}}</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="top_2">
            <div class="top_menu">
                <router-link class="item" tag="div" :to="{path: '/sports', query: {t: new Date().getTime()}}">
                    <span>{{i18nConst.MENU['SPORTS'][$store.state.country]}}</span>
                </router-link>
                <router-link class="item" tag="div" :to="{path: '/sports_inplay', query: {t: new Date().getTime()}}">
                    <span>{{i18nConst.MENU['INPLAY'][$store.state.country]}}</span>
                </router-link>
                <router-link class="item" tag="div" :to="{path: '/casino', query: {t: new Date().getTime()}}">
                    <span>{{i18nConst.MENU['CASINO'][$store.state.country]}}</span>
                </router-link>
                <router-link class="item" tag="div"
                             :to="{path: '/leisure/bet365_premiership', query: {t: new Date().getTime()}}">
                    <span>{{i18nConst.MENU['VSPORTS'][$store.state.country]}}</span>
                </router-link>
                <!--                <router-link class="item" tag="div"  :to="{path: '/leisure/powerball', query: {t: new Date().getTime()}}">-->
                <!--                    파워볼-->
                <!--                </router-link>-->
                <router-link class="item" tag="div" :to="{path: '/sports_betinfo', query: {t: new Date().getTime()}}">
                    <span>{{i18nConst.MENU['BETLIST'][$store.state.country]}}</span>
                </router-link>
                <router-link class="item" tag="div" :to="{path: '/customer_center', query: {t: new Date().getTime()}}">
                    <span>{{i18nConst.MENU['QUESTION'][$store.state.country]}}</span>
                </router-link>
                <router-link class="item" tag="div" :to="{path: '/notice', query: {t: new Date().getTime()}}">
                    <span>{{i18nConst.MENU['NOTICE'][$store.state.country]}}</span>
                </router-link>
                <router-link class="item" tag="div" :to="{path: '/recharge', query: {t: new Date().getTime()}}">
                    <span>{{i18nConst.MENU['COININ'][$store.state.country]}}</span>
                </router-link>
                <router-link class="item" tag="div" :to="{path: '/exchange', query: {t: new Date().getTime()}}">
                    <span>{{i18nConst.MENU['COINOUT'][$store.state.country]}}</span>
                </router-link>

            </div>
        </div>

        <div class="mobile-menu-right-icon">
            <img src="../assets/images/icon/common/login1.svg" class="" alt="" v-if="!$store.state.isLogin"
                 @click="showLoginPanel()">
            <img src="../assets/images/icon/common/user1.svg" class="" alt="" v-if="$store.state.isLogin"
                 @click="rightBarHandle">
        </div>
        <!--모바일 메뉴-->
        <mobile-top-menu></mobile-top-menu>
    </div>
</template>

<script>
    import sportsConst from "../common/sportsConst";
    import leisureConst from "../common/leisureConst";
    import MobileTopMenu from "./mobile/MobileTopMenu";
    import MobileTopUserInfo from "./mobile/MobileTopUserInfo";
    import {getLineMessage, getSiteStatus, logout} from "../network/userRequest";
    import {INIT_USERINFO} from "../store/mutation-types";
    import {coinPrice} from "../network/coinRequest";
    import {setCountry} from "../network/userRequest";
    import i18nConst from "../common/i18nConst";
    import store from "../store";

    export default {
        name: "TopbarComp",
        components: {MobileTopUserInfo, MobileTopMenu},
        data() {
            return {
                leisureConst,
                cross: sportsConst.GAME_DISPLAYPOSITION_1x2,
                handicap: sportsConst.GAME_DISPLAYPOSITION_HANDICAPOVERUNDER,
                special: sportsConst.GAME_DISPLAYPOSITION_SPECIAL,
                live: sportsConst.GAME_DISPLAYPOSITION_LIVE,
                lineMessage: '',
                i18nConst:i18nConst
            }
        },
        methods: {
            changeCountry(c) {
                if(c.target.value == 'kr'){
                    this.$store.state.country = i18nConst.KR
                    setCountry(i18nConst.KR)
                }
                else if(c.target.value == 'en'){
                    this.$store.state.country = i18nConst.EN
                    setCountry(i18nConst.EN)
                }
                else if(c.target.value == 'jp'){
                    this.$store.state.country = i18nConst.JP
                    setCountry(i18nConst.JP)
                }
                else if(c.target.value == 'fra'){
                    this.$store.state.country = i18nConst.FRA
                    setCountry(i18nConst.FRA)
                }
                else if(c.target.value == 'spa'){
                    this.$store.state.country = i18nConst.SPA
                    setCountry(i18nConst.SPA)
                }
            },
            setCountry(c) {
                this.$store.state.country = c
                setCountry(c)
            },
            rightBarHandle() {
                this.$bus.$emit('rightBarHandle', true)
            },
            showLoginPanel() {
                if (!this.$store.state.isLogin && !this.$store.state.isShowLoginPanel) {
                    this.$store.state.isShowLoginPanel = true
                    this.$store.state.isShowRegisterPanel = false
                }
            },
            showRegisterPanel() {
                if (!this.$store.state.isLogin) {
                    this.$store.state.isShowRegisterPanel = true
                    this.$store.state.isShowLoginPanel = false
                }
            },
            changeSportsDisplay(disp) {
                //SportsOriginal.vue -> $on
                //SportsLinksComp.vue ->on
                this.$bus.$emit('sportsDisplayChanged', disp);
            },
            showMobileTopMenu() {
                //모바일 메뉴아이콘 클릭시
                //MobileTopMenu.vue에서 $on
                this.$bus.$emit('mobileTopMenuActive')
            },
            showMobileUserInfo() {
                //모바일 유저아이콘 클릭시
                //MobileTopUserInfo.vue에서 $on
                this.$bus.$emit('mobileTopUserInfoActive')
            },
            logout() {
                this.$swal({
                    title: i18nConst.COMMON['ISLOGOUT'][this.$store.state.country],
                    type: 'question',
                    confirmButtonText: i18nConst.COMMON['LOGOUT'][this.$store.state.country],
                }).then((res) => {
                    if (res.value) {
                        this.$store.commit(INIT_USERINFO);
                        window.sessionStorage.clear();
                        logout();
                        this.$router.replace("/main")
                    }
                })

            },
            toMain() {
                this.$router.replace("/main")
            },
            getLineMessage() {
                getLineMessage().then(res => {
                    if (res.data.success) {
                        let lines = res.data.data;
                        lines.map(line => {
                            this.lineMessage = this.lineMessage + '  ➤   ' + line.content
                        })
                    }

                })
            },
            browser() {
                let u = navigator.userAgent
                let app = navigator.appVersion
                return {         //移动终端浏览器版本信息
                    trident: u.indexOf('Trident') > -1, //IE
                    presto: u.indexOf('Presto') > -1, //opera
                    webKit: u.indexOf('AppleWebKit') > -1, //Apple,Google
                    gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //FireFox
                    mobile: !!u.match(/AppleWebKit.*Mobile.*/), //
                    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //
                    android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //
                    iPhone: u.indexOf('iPhone') > -1, //
                    iPad: u.indexOf('iPad') > -1, //
                    webApp: u.indexOf('Safari') == -1//
                }
            }
        },
        created() {
            if (this.$store.state.isLogin == false) {
                if(this.$route.path.indexOf('/main') == -1){
                    this.$router.replace("/main")
                    store.state.isLogin = false;
                    store.state.isShowLoginPanel = true
                }

            }
            this.$store.state.ismobile = this.browser().mobile
            //랭킹,보너스,베팅금액,당첨금액 등 설정요청
            this.$store.dispatch('actionCommonConfig')
            this.getLineMessage();
            getSiteStatus().then(res => {
                if (!res.data.success) {
                    this.$store.state.isMaintaining = true;
                    this.$store.state.maintainingMessage = res.data.msg
                    this.$store.state.isLogin = false;
                    logout()
                } else {
                    this.$store.state.isMaintaining = false;
                    this.$store.state.maintainingMessage = ''
                }
            })
            if (this.$store.state.isLogin) {
                this.$store.dispatch('actionUserInfo')
                this.$store.dispatch('actionUnCheckedMessageCount')
            } else {
                this.$store.commit(INIT_USERINFO);
            }
        },
    }
</script>

<style scoped>
    /* 해더 */
    .header {
        width: 100%;
        height: 160px;
        background-color: #052a71;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        position: fixed;
        top: 0;
        left: 0;
        transition: width .2s ease-in-out, left .2s ease-in-out;
        z-index: 1010;
        box-sizing: border-box;
        box-shadow: -3px 6px 6px -3px rgb(5 42 113 / 35%);
    }

    .header .top_1 {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        height: 100px;
        box-sizing: border-box;
        padding: 0 336px;
    }

    .header .top_1 .top_logo {
        width: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .header .top_1 .top_buttons {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .header .top_2 {
        width: 100%;
        height: 58px;
        background-color: #0b3987;
        box-sizing: border-box;
        padding: 0 336px;
        border-top: 2px solid #ff8901;
    }

    .header .top_2 .top_menu {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header .top_2 .top_menu .item {
        flex: 1;
        height: 90%;
        text-align: center;
        color: #ffffff;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.3s all;
        box-sizing: border-box;
        padding: 0 10px;

    }

    .header .top_2 .top_menu .item:hover {
        color: #e3850c
    }


    .header .mobile-menu-left-icon {
        display: none;
    }

    .header .mobile-menu-right-icon {
        display: none;
    }

    .header .login-button {
        margin-right: 10px;
        background-color: #1c6afe;
    }


</style>

