<template>
  <div class="mobile_userinfo_panel" :class="{'activeMobileUserInfo':isShowTopUserInfo}">
    <div class="close">
      <a class="btn btn02"  @click="closeMobileTopUserInfo()">
        <i class="fa fa-sign-out" aria-hidden="true"></i>{{i18nConst.COMMON['CLOSE'][$store.state.country]}}
      </a>
    </div>
    <user-info-comp></user-info-comp>
  </div>
</template>

<script>
  import UserInfoComp from "../UserInfoComp";
  import i18nConst from "../../common/i18nConst";

  export default {
    name: "MobileTopUserInfo",
    components: {UserInfoComp},
    data() {
      return {
        i18nConst:i18nConst,
        isShowTopUserInfo: false
      }
    },
    methods:{
      closeMobileTopUserInfo(){
        this.isShowTopUserInfo = false
      }
    },
    created() {
      this.$bus.$on('mobileTopUserInfoActive', () => {
        this.isShowTopUserInfo = true
      })
    }
  }
</script>

<style scoped>


</style>