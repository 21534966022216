import http from "./axios/http";

export function dobet(betInfo) {
  return http.post('/sports/bet/dobet', betInfo)
}

/*베팅내역*/
export function getBetList(search, pageNum, pageSize, orderBy) {
  return http.post('/sports/bet/my_bet_list?pageNum=' + pageNum + '&pageSize=' + pageSize + '&orderBy=' + orderBy, search)
}


export function cancelBet(id) {
  return http.get('/sports/bet/cancel?id=' + id);
}

export function deleteBet(id) {
  return http.get('/sports/bet/del?id=' + id);
}

export function doinplaybet(betInfo) {
  return http.post('/sports/inplay/bet/doinplaybet', betInfo)
}
