<template>
    <div>

        <popup-comp></popup-comp>

        <!--읽지 않은 메세지-->
        <message-comp></message-comp>


        <div class="footer">
            <div class="footer_cont">
                <div class="f1">
                    <div class="f1_left">
                        <router-link tag="div" to="/rule_use"><span>{{i18nConst.MENU['TERMS_OF_SERVICE'][$store.state.country]}}</span></router-link>
                        <router-link tag="div" to="/rule_site"><span>{{i18nConst.MENU['OPERATION_POLICY'][$store.state.country]}}</span></router-link>
                        <router-link tag="div" to="/rule_userinfo"><span>{{i18nConst.MENU['PRIVACY_POLICY'][$store.state.country]}}</span></router-link>
                        <router-link tag="div" to="/rule_email"><span>{{i18nConst.MENU['EMAIL_COLLECTION'][$store.state.country]}}</span></router-link>
                        <router-link tag="div" to="/rule_partner"><span>{{i18nConst.MENU['PARTNER_INQUIRY'][$store.state.country]}}</span></router-link>
                        <router-link tag="div" to="/rule_qa"><span>{{i18nConst.MENU['QA'][$store.state.country]}}</span></router-link>
                    </div>
                    <div class="f1_right">
                        <div class="f1_imgs">
                            <img src="../assets/images/foot/betstoa/18.svg" alt="">
                            <img src="../assets/images/foot/betstoa/facebook.svg" alt="">
                            <img src="../assets/images/foot/betstoa/twitter.svg" alt="">
                            <img src="../assets/images/foot/betstoa/instagram-fill.svg" alt="">
                        </div>
                        <div class="txt">
                            ©2010 - 2022 BETSTOA ASIA
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer2">
            <div class="footer_cont2">
                <span class="" v-if="$store.state.country === i18nConst.KR">
                    <p> BETSTOA 은 NEVADA state business license 로 등록된 회사인 BETSTOA 의 소유이다. BETSTOA Nevada Business Identification # NV20222442048</p>
                    <p> 미국 네바다주에서 승인한 게임서비스를 라이센스번호 Certificate Number : B202205032637676, BETSTOA로 운영됩니다.</p>

                </span>
                <span class="" v-if="$store.state.country === i18nConst.EN">
                   <p> BETSTOA is owned by BETSTOA, a company registered under the NEVADA state business license. BETSTOA Nevada Business Identification # NV20222442048</p>
                    <p> The game service approved by the US state of Nevada is operated under the license number Certificate Number: B202205032637676, BETSTOA.</p>
                </span>
                <span class="" v-if="$store.state.country === i18nConst.JP">
                    <p> BETSTOAはNEVADA state business licenseで登録された会社であるBETSTOAの所有です。 BETSTOA Nevada Business Identification #NV20222442048</p>
                    <p> 米国ネバダ州で承認されたゲームサービスをライセンス番号Certificate Number：B202205032637676、BETSTOAで運営されています。</p>
                </span>
                <span class="" v-if="$store.state.country === i18nConst.FRA">
                   <p> BETSTOA appartient à BETSTOA, une société enregistrée sous la licence commerciale de l'État du NEVADA. BETSTOA Nevada Numéro d'identification d'entreprise NV20222442048</p>
                    <p> Le service de jeu approuvé par l'État américain du Nevada est exploité sous le numéro de licence Numéro de certificat : B202205032637676, BETSTOA.</p>
                </span>
                <span class="" v-if="$store.state.country === i18nConst.SPA">
                   <p> BETSTOA es propiedad de BETSTOA, una empresa registrada bajo la licencia comercial estatal de NEVADA. Número de identificación comercial de BETSTOA Nevada NV20222442048</p>
                    <p> El servicio de juegos aprobado por el estado estadounidense de Nevada se opera con el número de licencia Número de certificado: B202205032637676, BETSTOA.</p>
                </span>

            </div>
        </div>

    </div>


</template>

<script>
    import MessageComp from "./MessageComp";
    import PopupComp from "./PopupComp";
    import i18nConst from "../common/i18nConst";

    export default {
        name: "FootComp",
        data() {
            return {
                i18nConst:i18nConst,
            }
        },
        components: {PopupComp, MessageComp},
        created() {
        }
    }
</script>

<style scoped>
    .footer {
        width: 100%;
        padding: 0px 320px 0px 320px;
        color: #292929;
        background-color: #e6e6e6;
    }

    .footer .footer_cont {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .footer .footer_cont .f1 {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .footer .footer_cont .f1 .f1_left {
        width: 75%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
    }

    .footer .footer_cont .f1 .f1_left div {
        flex: 1;
        text-align: center;
        cursor: pointer;
        padding: 10px 0;
    }

    .footer .footer_cont .f1 .f1_right {
        width: 25%;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        flex-wrap: nowrap;
    }

    .footer .footer_cont .f1 .f1_right .f1_imgs {
        width: 100%;
        display: flex;
        justify-content: flex-end;

    }

    .footer .footer_cont .f1 .f1_right .f1_imgs img {
        width: 30px;
        height: 30px;
        margin: 0 10px;
    }

    .footer .footer_cont .f1 .f1_right .txt {
        padding: 10px 0;
        font-size: 14px;
        font-weight: bold;
    }

    .footer2 {
        width: 100%;
        padding: 0px 320px 0px 320px;
        color: #292929;
        background-color: #cfcfcf;
    }

    .footer2 .footer_cont2 {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 90px;
        background-color: #cfcfcf;
    }
    @media screen and (max-width: 1024px) {
        .footer .footer_cont .f1 .f1_left{
            width: 100%;
            height: 50px!important;
            padding: 10px 0;
        }
        .footer .footer_cont .f1 .f1_right{
            width: 100%;
            align-items: center!important;
        }
        .footer .footer_cont .f1 .f1_right .f1_imgs{
            justify-content: center!important;
        }
        .footer2{
            padding: 10px 10px!important;
        }
        .footer2 .footer_cont2 p{
            width: 100%;
        }
    }

</style>