<template>
    <div class="overlay" :class="{'showRegisterPannel':$store.state.isShowRegisterPanel && !$store.state.isLogin}">
        <div class="overlay-warp">
            <div class="overlay-open">

                <div class="register-warp">
                    <div class="login-head">
                        <h4>JOIN BETSTOA</h4>
                        <div class="login-panel-close" @click="closePanel">
                            <img src="../assets/images/icon/common/close1.svg"/>
                        </div>
                    </div>
                    <div class="register-cont">
                        <div class="register-d">
                            <div class="register-left">
                                <div class="register-from">
                                    <div class="form-field">
                                        <span><span>{{i18nConst.USER['USER_NAME'][$store.state.country]}}</span></span>
                                        <input type="text" v-model="user.username"  :placeholder="i18nConst.USER['USER_NAME_PLACEHOLDER'][$store.state.country]"/>
                                    </div>
                                    <div class="form-field">
                                        <span>{{i18nConst.USER['NICK_NAME'][$store.state.country]}}</span>
                                        <input type="text" v-model="user.nickname" :placeholder="i18nConst.USER['NICK_NAME_PLACEHOLDER'][$store.state.country]"/>
                                    </div>
                                    <div class="form-field">
                                        <span>{{i18nConst.USER['PASSWORD'][$store.state.country]}}</span>
                                        <input type="password" v-model="user.passwd" :placeholder="i18nConst.USER['PASSWORD_PLACEHOLDER'][$store.state.country]"/>
                                    </div>
                                    <div class="form-field">
                                        <span>{{i18nConst.USER['RE_PASSWORD'][$store.state.country]}}</span>
                                        <input type="password" v-model="user.confirm_password" :placeholder="i18nConst.USER['RE_PASSWORD_PLACEHOLDER'][$store.state.country]"/>
                                    </div>
                                    <div class="form-field">
                                        <span>{{i18nConst.USER['PHONE'][$store.state.country]}}</span>
                                        <input type="number" v-model="user.phone" :placeholder="i18nConst.USER['PHONE_PLACEHOLDER'][$store.state.country]"/>
                                    </div>
                                    <div class="form-field">
                                        <span>EMAIL</span>
                                        <input type="email" v-model="user.email"
                                               placeholder="EMAIL"/>
                                    </div>
                                    <div class="form-field">
                                        <span>{{i18nConst.USER['SIGNUP_CODE'][$store.state.country]}}</span>
                                        <input type="text" v-model="user.code" :placeholder="i18nConst.USER['SIGNUP_CODE_PLACEHOLDER'][$store.state.country]"/>
                                    </div>
                                </div>
                                <div class="register-from-button">
                                    <loading-login-comp v-if="clickedLogin"></loading-login-comp>
                                    <button class="mb-button--primary mb-button--wider login" @click="doRegister"
                                            v-if="!clickedLogin">{{i18nConst.USER['SIGN_UP'][$store.state.country]}}
                                    </button>
                                </div>
                                <div class="error-message" v-show="erm != null">
                                    {{erm}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
    import {loginRegisterMixin} from "../common/mixin";
    import {register} from "../network/userRequest";
    import LoadingLoginComp from "./LoadingLoginComp";
    import i18nConst from "../common/i18nConst";

    export default {
        name: "RegisterComp",
        components: {LoadingLoginComp},
        mixins: [loginRegisterMixin],
        data() {
            return {
                user: {
                    username: '',
                    passwd: '',
                    confirm_password: '',
                    nickname: '',
                    phone: '',
                    email: '',
                    code: ''
                },
                error: {
                    username: '',
                    passwd: '',
                    confirm_password: '',
                    nickname: '',
                    phone: '',
                    verificationCode: '',
                    bank: '',
                    beneficiary: '',
                    acNo: '',
                    extractPasswd: '',
                    code: ''
                },
                status: {
                    username: false,
                    passwd: false,
                    confirm_password: false,
                    nickname: false,
                    phone: false,
                    verificationCode: false,
                    bank: false,
                    beneficiary: false,
                    acNo: false,
                    extractPasswd: false,
                    code: false
                },
                isUsePhoneCode: false,
                isShowMessage: false,
                content: '',
                erm: null,
                clickedLogin: false,
                i18nConst:i18nConst,
            }
        },
        methods: {
            closePanel() {
                this.$store.state.isShowLoginPanel = false;
                this.$store.state.isShowRegisterPanel = false;
            },
            doRegister() {
                this.clickedLogin = true
                this.erm = null
                /* if (!this.checkUserName()) {
                     return false;
                 }
                 if (!this.checkNickName()) {
                     return false;
                 }
                 if (!this.checkPasswd()) {
                     return false;
                 }
                 if (!this.checkConfirmPassword()) {
                     return false;
                 }
                 if (!this.checkPhone()) {
                     return false;
                 }
                 if (!this.checkBank()) {
                     return false;
                 }
                 if (!this.checkAcNo()) {
                     return false;
                 }
                 if (!this.checkBeneficiary()) {
                     return false;
                 }
                 if (!this.checkCode()) {
                     return false;
                 }*/
                if (!this.checkConfirmPassword()) {
                    this.erm = i18nConst.USER["RE_PASSWORD_ERROR_MSG"][this.$store.state.country]
                    this.clickedLogin = false
                    return false;
                }

                register(this.user).then(res => {
                    if (res.data.success) {
                        this.$store.state.isShowLoginPanel = false
                        this.$store.state.isShowRegisterPanel = false
                        this.$swal({
                            title: i18nConst.USER["WELCOME_NEW_USER_MSG"][this.$store.state.country],
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })

                    } else {
                        this.erm = res.data.msg
                        this.erm = 'ERROR: ' + this.erm
                        console.log(this.erm)
                    }
                    this.clickedLogin = false
                })
            }
            ,
            checkUserName() { //회원가입시 아이디 체크
                if (!this.testRegxUserName) {
                    this.error.username = i18nConst.USER["USER_NAME_ERROR_MSG"][this.$store.state.country],
                    this.status.username = false;
                    return false;
                }
                this.error.username = '';
                return true;
                /*//아이디 사용가능 여부 체크
                this.$store.commit(RECEIVE_SHOW_LOADING)
                checkUserName(this.user.username).then(res => {
                    if (res.data.success) {
                        this.error.username = ''
                        this.status.username = true;
                    } else {
                        this.error.username = res.data.msg
                        this.status.username = false;
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                })*/
            },
            checkNickName() { //회원가입시 닉네임 체크
                if (!this.testRegxNickname) {
                    this.error.nickname = i18nConst.USER["NICK_NAME_ERROR_MSG"][this.$store.state.country]
                    this.status.nickname = false;
                    return false;
                }
                this.error.nickname = '';
                return true;
                //아이디 사용가능 여부 체크
                /*this.$store.commit(RECEIVE_SHOW_LOADING)
                checkNickName(this.user.nickname).then(res => {
                    if (res.data.success) {
                        this.error.nickname = ''
                        this.status.nickname = true;
                    } else {
                        this.error.nickname = res.data.msg
                        this.status.nickname = false;
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                })*/
            },
            checkPasswd() {
                if (!this.testRegxPasswd) {
                    this.error.passwd =  i18nConst.USER["PASSWORD_ERROR_MSG"][this.$store.state.country];
                    this.status.passwd = false;
                    return false;
                }
                this.error.passwd = '';
                this.status.passwd = true;
                return true;
            },
            checkConfirmPassword() {
                if (this.user.confirm_password.trim() === '' || this.user.passwd.trim() !== this.user.confirm_password.trim()) {
                    this.error.confirm_password = i18nConst.USER["RE_PASSWORD_ERROR_MSG"][this.$store.state.country];
                    this.status.confirm_password = false;
                    return false;
                }
                this.error.confirm_password = '';
                this.status.confirm_password = true;
                return true;

            },
            checkPhone() {
                if (!/^[0-9]{11,12}$/.test(this.user.phone)) {
                    this.error.phone = i18nConst.USER["PHONE_ERROR_MSG"][this.$store.state.country]
                    this.status.phone = false;
                    return false;
                }
                this.error.phone = '';
                this.status.phone = true;
                return true;

            },

            checkBank() {
                if (this.user.bank === '') {
                    this.error.bank = '은행명을 선택하세요'
                    this.status.bank = false;
                    return false;
                }
                this.error.bank = ''
                this.status.bank = true;
                return true;
            },
            checkBeneficiary() {
                if (!/^.{1,10}$/.test(this.user.beneficiary)) {
                    this.error.beneficiary = '예금주를 입력하세요'
                    this.status.beneficiary = false;
                    return false;
                }
                this.error.beneficiary = ''
                this.status.beneficiary = true;
                return true;

            },
            checkAcNo() {
                if (!/^[0-9]{5,20}$/.test(this.user.acNo)) {
                    this.error.acNo = '계좌번호 오류입니다.\'-\'없이 숫자로만 입력하세요'
                    this.status.acNo = false;
                    return false;
                }
                this.error.acNo = '';
                this.status.acNo = true;
                return true;

            },

            checkCode() {
                if (!/^[0-9a-zA-Z]{2,50}$/.test(this.user.code)) {
                    this.error.code =  i18nConst.USER["REFERRE_ERROR_MSG"][this.$store.state.country];
                    this.status.code = false;
                    return false;
                }
                this.error.code = '';
                this.status.code = true;
                return true;
            },
        },
        created() {
        },
        computed: {
            allowUsername() {
                return /^[a-z][0-9a-z]{3,20}$/.test(this.username)
            },
            allowPasswd() {
                return /^.{3,30}$/.test(this.passwd)
            }
        }
    }
</script>

<style scoped>

    @media screen and (max-width: 1024px) {
        .register-warp {
            width: 370px !important;
            height:620px !important;
            background-color: #fff9ff;
        }

        .register-warp .register-cont .register-d .register-left {
            width: 100% !important;
        }

        .register-warp .register-cont .register-d .register-right {
            display: none !important;
        }
    }

    .showRegisterPannel {
        transform: translateY(0%);
    }

    .register-warp {
        width: 400px;
        height: 620px;
        background-color: #fff9ff;
    }

    .register-warp .login-head {
        background-color: #0b3987;
        color: #fff;
        height: 40px;
        line-height: 40px;
        padding: 0 16px;
        position: relative;
        text-transform: uppercase;
        width: 100%;
        z-index: 5002;
    }

    .register-warp .login-head h4 {
        -webkit-align-items: center;
        align-items: center;
        display: -webkit-inline-flex;
        display: inline-flex;
        font-size: 14px;
        font-weight: 700;
        -webkit-justify-content: center;
        justify-content: center;
        margin: 0;
        vertical-align: middle;
    }

    .login-panel-close {
        position: absolute;
        top: 0;
        right: 0;
        width: auto;
        height: 40px;
        padding: 2px;
        cursor: pointer;
    }

    .login-panel-close img {
        height: 30px;
    }

    .register-warp .register-cont {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        overflow: hidden;
        position: relative;
        z-index: 5001;
        margin-top: -40px;
    }

    .register-warp .register-cont .register-d {
        display: flex;
        width: 100%;
        height: 100%;
        padding-top: 40px;
    }

    .register-warp .register-cont .register-d .register-left {
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 5002;
        position: relative;
    }

    .error-message {
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: #ff0000;
    }

    .register-warp .register-cont .register-d .register-left .register-logo {
        text-align: center;
        margin-top: 24px;
    }

    .register-warp .register-cont .register-d .register-left .register-from {
        width: 100%;
        padding: 0 16px 16px;
    }

    .register-warp .register-cont .register-d .register-left .register-from .form-field {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 44px;
        margin-top: 20px;
    }

    .register-warp .register-cont .register-d .register-left .register-from .form-field span {
        height: 28px;
        line-height: 28px;
        font-weight: bold;
        font-size: 13px;
    }

    .register-warp .register-cont .register-d .register-left .register-from .form-field input {
        background: 0 0;
        border: 0;
        border-bottom: 1px solid #a3a1a7;
        border-radius: 0;
        color: #43444a;
        font-size: 15px;
        height: 25px;
        line-height: 25px;
        outline: 0;
        padding: 0;
        width: 100%;
        text-align: left;
    }

    .register-warp .register-cont .register-d .register-left .register-from-button {
        width: 100%;
        padding: 0 16px 16px;
        margin-top: 28px;
    }

    .register-warp .register-cont .register-d .register-left .login {
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        font-weight: bold;
    }

    .register-warp .register-cont .register-d .register-left .register {
        height: 36px;
        line-height: 36px;
        margin-top: 28px;
        font-weight: bold;
        font-size: 14px;
    }

    .register-warp .register-cont .register-d .register-left .tel-message {
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: #0b1423;
        line-height: 28px;
    }

    .register-warp .register-cont .register-d .register-left .login-bottom {
        width: 100%;
        background-color: #2b2f35;
        position: absolute;
        bottom: 0;
        padding: 16px;
    }

    .register-warp .register-cont .register-d .register-left .login-bottom .join-text {
        color: #fff9ff;
        font-size: 12px;
    }

    /*login panel right*/
    .register-warp .register-cont .register-d .register-right {
        width: 50%;
        height: 100%;
        overflow: hidden;
        z-index: 5002;
    }

</style>