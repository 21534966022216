<template>
    <!--오른쪽 메뉴-->
    <div class="user_box" v-if="$store.state.isLogin">
        <div v-if="$store.state.isLogin">
            <div class="logininfo">
                <div class="infoleft" style="display: flex;align-items: center;justify-content: center">
                    <img :src="$store.state.userInfo.rank.imageUrl" width="80">
                </div>
                <div class="inforight">
                    <div class="line underline">
                        <span class="">{{$store.state.userInfo.nickname}}</span>

                    </div>
                    <div class="line underline ">
                        {{i18nConst.BET['GAME_MONEY'][$store.state.country]}} <span
                            class="text-blue">{{$store.state.userInfo.cash|comma}}</span> $
                    </div>
<!--                    <div class="line underline">-->
<!--                        포인트 <span-->
<!--                            class="text-blue">{{$store.state.userInfo.userPoint|comma}}</span> 점-->
<!--                        <button @click="changePoint" class="btn-change-point">전환</button>-->
<!--                    </div>-->
                    <div class="line">
                        {{i18nConst.CASINO['CASINO_CASH'][$store.state.country]}} <span
                            class="text-blue">{{Math.ceil($store.state.userInfo.casinocash / $store.state.commonConfig.hlv)|comma}}</span> <i
                            class="fa fa-refresh"
                            style="cursor: pointer;color: #c51a1b" @click="refreshCasinoCash()"></i>
                    </div>

                </div>
            </div>
        </div>
        <div v-if="!$store.state.isLogin && !$store.state.isMaintaining">
            <div class="right_login">
                <div class="id_panel">
                    <input type="text" v-model="user.username" :placeholder="i18nConst.USER['USER_NAME'][$store.state.country]" style="line-height: 28px">
                </div>
                <div class="pw_panel">
                    <input type="password" v-model="user.passwd" :placeholder="i18nConst.USER['PASSWORD'][$store.state.country]" style="line-height: 28px">
                </div>
                <div class="button_panel">
                    <button class="btn btn02" style="line-height: 28px" @click="doLogin">{{i18nConst.COMMON['LOGIN'][$store.state.country]}}</button>
                    <router-link tag="button" to="/register" class="btn btn02">{{i18nConst.COMMON['REGISTE'][$store.state.country]}}</router-link>
                </div>
            </div>
        </div>

        <!--        <right-bar-banner-comp class="mobile_right_bar_banner"></right-bar-banner-comp>-->
    </div >


</template>

<script>
    import {logout, changePoint, login, getUserInfo} from "../network/userRequest";
    import {getAccountBalance} from "../network/casinoRequest";
    import {
        INIT_USERINFO,
        RECEIVE_HIDE_LOADING,
        RECEIVE_SHOW_LOADING,
        RECEIVE_USER_INFO
    } from "../store/mutation-types";
    import RightBarBannerComp from "./RightBarBannerComp";
    import {loginRegisterMixin} from "../common/mixin";
    import i18nConst from "../common/i18nConst";

    export default {
        name: "UserInfoComp",
        mixins: [loginRegisterMixin],
        components: {RightBarBannerComp},
        data() {
            return {
                i18nConst:i18nConst,
                isShowSubordinate: false,
                user: {
                    username: '',
                    passwd: '',
                },
            }
        },
        methods: {
            subordinate() {
                this.isShowSubordinate = !this.isShowSubordinate;
            },
            doLogin() {
                // if (!this.testRegxUserName) {
                //     this.$swal({
                //         title: '아이디는 4-15자리 영문,숫자조합입니다',
                //         type: 'error',
                //         showCancelButton: false,
                //         showConfirmButton: true
                //     })
                //     return false;
                // }
                // if (!this.testRegxPasswd) {
                //     this.$swal({
                //         title: '비밀번호는 4-20자리 입니다',
                //         type: 'error',
                //         showCancelButton: false,
                //         showConfirmButton: true
                //     })
                //     return false;
                // }
                this.$store.commit(RECEIVE_SHOW_LOADING);
                login(this.user).then(res => {
                    if (res.data.success) {
                        getUserInfo().then(res => {
                            this.$store.state.isLogin = true;
                            const userInfo = res.data.data
                            this.$store.commit(RECEIVE_USER_INFO, {userInfo});
                            window.sessionStorage.setItem("isLogin", 'true');
                            this.$store.commit(RECEIVE_HIDE_LOADING);
                            this.$router.push('/main').catch(error => {
                                console.info(error.message)
                            });
                        }, err => {
                            console.log(err)
                        })
                    } else {
                        this.$store.commit(RECEIVE_HIDE_LOADING);
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
            logout() {
                this.$swal({
                    title: i18nConst.COMMON['ISLOGOUT'][this.$store.state.country],
                    type: 'question',
                    confirmButtonText: i18nConst.COMMON['LOGOUT'][this.$store.state.country],
                }).then((res) => {
                    if (res.value) {
                        this.$store.commit(INIT_USERINFO);
                        window.sessionStorage.clear();
                        logout();
                        this.$router.replace("/login")
                    }
                })

            },
            initUserInfo() {
                this.$store.dispatch('actionUserInfo')
            },
            initUncheckedMessage() {
                this.$store.dispatch('actionUnCheckedMessageCount')
            },
            changePoint() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                changePoint().then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (!res.data.success) {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.initUserInfo()
                    }
                })
            },
            refreshCasinoCash() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                getAccountBalance().then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
                })
            }
        },
        created() {

        }
    }
</script>

<style scoped>
    .user_box {
        width: 100%;
        padding: 16px 5px 2px 5px;
    }

    .user_box .logininfo {
        width: 100%;
        display: flex;
        justify-content: space-between;
        --border: 1px solid #dddddd;
        --background-color: #2b2f35;
        color: #0b1423;
    }

    .user_box .logininfo .infoleft {
        width: 40%;
    }

    .user_box .logininfo .inforight {
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: start;
        box-sizing: border-box;
        padding-left:0px;
        color: #0b1423;
    }
    .user_box .logininfo .inforight .btn-change-point{
        background-color: #ff8b03;
        padding: 3px 5px;
        border-radius: 3px;
    }

    .user_box .logininfo .inforight .line {
        width: 100%;
        height: 36px;
        line-height: 36px;
        text-align: left;
        box-sizing: border-box;
        padding-left: 0px;
        font-weight: bold;
    }

    .user_box .logininfo .inforight .underline {
        --border-bottom: 1px solid #30312c;
    }

    .right_login {
        width: 100%;
    }

    .right_login .id_panel {
        margin: 5px 0;
    }

    .right_login input {
        text-align: center;
        width: 100%;
        background-color: #f4f4f4;
        height: 28px;
        line-height: 28px;
        color: #0b1423;
    }

    .right_login .pw_panel {
        margin: 5px 0;
    }

    .right_login .button_panel {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    .right_login .button_panel button {
        width: 49.5%;
    }


    .userinfotable td {
        font-size: 14px !important;
        font-weight: bold;
    }


    @media screen and (max-width: 1024px) {
        .user_box {
            --display: none !important;
            padding-top: 8px!important;
        }
        .right_login {
            margin-top: 10px !important;
        }

        .right_login input {
            height: 38px !important;
            line-height: 38px !important;
        }

        .right_login .button_panel button {
            line-height: 38px !important;
        }

    }


</style>