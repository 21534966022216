import http from "./axios/http";


/*코인 환율*/
export function coinPrice() {
    return http.get('/coin/get_coin_price')
}

/*코인 개수*/
export function coinBalance() {
    return http.get('/coin/get_coin_balance')
}
